import React from 'react';
import Header from './Header';

const DriverDashboard = () => {

    const userData = JSON.parse(localStorage.getItem('user'));

    return (
    <div>
        <Header />
        <div className='container'>
            <h2>Welcome {userData.username} to your {userData.role.toUpperCase()} dashboard</h2>
            <p>Update your profile any time you want</p>

        </div>
    </div>
    )
}

export default DriverDashboard;