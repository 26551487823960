import React, {useState, useEffect, useContext} from 'react';
import { Header } from './';
import { Pagination } from '../components';
import moment from 'moment';
import { useNavigate, Link } from 'react-router-dom';
import { DashboardMenu } from '../components';
import { SiteContext } from '../context';
import ReactModal from 'react-modal';

const OrgsUser = () => {

  let navigate = useNavigate();

const { apiUrl } = useContext(SiteContext);

const userData = JSON.parse(localStorage.getItem('user'));

const [organisations, setOrgs] = useState([]);
const [total, setTotal] = useState(null);
const [limit, setLimit] = useState(10);
const [page, setPage] = useState(1);
const [cangoBack, setCangoBack] = useState(true);
const [cangoForward, setCangoForward] = useState(true);
const [updateItem, setUpdateItem] = useState({});
const [itemSelected, setItemSelected] = useState('');
const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');
const [showModal, setShowModal] = useState(false);

useEffect(() => {
  getOrgs();
},[page,limit])

const getOrgs = () => {
  fetch(`${apiUrl}/api/organisations.php?action=fetchorgsuser&username=${userData.username}&role=${userData.role}&page=${page}&limit=${limit}`, {
    method:'get'
  }).then((res) => {
      return res.json()
  }).then((res) => {
    if(res.status === 200) {
      if(res.rows.length > 0) {
        setOrgs(res.rows);
        setTotal(res.count);      
    }
      } else if (res.status === 400) {
          setMsg(res.msg)
          setTimeout(() => {
            setMsg('')
          },3000)
      }
  }).catch((err) => {
      console.log(err)
  })
}

const doPrevious = async () => {
 
  if(parseInt(page) > 1) {
    await setPage(() => parseInt(page - 1));
    setCangoBack(true);
    setCangoForward(true);
  } else {
    setCangoBack(false);
    setCangoForward(true);
  }
}

const doNext = async () => {

  if(parseInt(page * limit) < total) { 
    await setPage(() => parseInt(page + 1));
    setCangoForward(true);
    setCangoBack(true);
  } else {
    setCangoForward(false);
    setCangoBack(true);
  }
}

const handleChange = (event) => {
  let newLimit = event.target.value;
  setLimit(newLimit);
}

  const handleUpdate = (organisation) => {
    navigate('/updateorganisationuser', {state: {'organisation':organisation, 'action': 'update'}});
  }

    return (
        <div>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                    <div className='container my-2'>
                <h3>Organisations</h3>
                <div className='my-2 d-flex justify-content-between'>
                  <div className='align-items-center'>
                    <div className='p-2'>{errorMsg !== '' ? <span className='text-danger'>{errorMsg}</span> : null} {msg !== '' ? <span className='text-dark'>{msg}</span> : null}</div>
                  </div>
                </div>

        {organisations.length > 0 && <Pagination limit={limit} page={page} total={total} cangoBack={cangoBack} cangoForward={cangoForward} handleChange={handleChange} doNext={doNext} doPrevious={doPrevious} />}

                {organisations && 
      <div className='container p-3'>
          <div className='row border p-1 bg-light'>
            <div className='col-1'>No</div>
            <div className='col-8'>Item</div>
            <div className='col-3'>Status</div>
          </div>
          {organisations.map((organisation,i) => {
            return (
              <div key={i} className='row border-bottom py-1'>
              <div className='col-1'>{++i}</div>
              <div className='col-8'><p className='h5'>{organisation.orgname}</p>
              Access code: <span className='h6'>{organisation.orgref}</span><br />
              <i className='bi bi-phone'></i>: {organisation.orgphone}<br />
              <i className='bi bi-envelope'></i>: {organisation.orgemail}<br />
              <i className='bi bi-clock'></i>: {moment(organisation.createdAt).format('Do MMM YYYY h:mma')}
              <p><Link to={'/orgmembersuser'} state={{orgref:organisation.orgref, org: organisation.orgname}} className='py-1'>Members</Link> | <Link to={'/contributionsuser'} state={{orgref:organisation.orgref, org: organisation.orgname}} className='py-1 px-2'>Contributions</Link> | <Link to={'/orgexpensesuser'} state={{orgref:organisation.orgref, org: organisation.orgname}} className='py-1'>Expenses</Link></p></div>
              <div className='col-3'>{organisation.orgstatus} <p><a onClick={() => handleUpdate(organisation)}><i className='bi bi-pencil text-dark h4'></i></a></p></div>
            </div>  
            )
          })}
            </div>}
            </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OrgsUser;