import React, { createContext, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

export const SiteContext = createContext();

export const SiteContextProvider = ({ children }) => {
  // const apiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PRO;
  const apiUrl = "https://duesandlevies.com.ng";
  const [userData, setUserData] = useState(null);
  const [siteconfig, setSiteconfig] = useState({});
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [orderTotal, setOrderTotal] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [isLoggedOn, setIsLoggedOn] = useState(false);
  const [msg, setMsg] = useState("");
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const singleProduct = true;

  useEffect(() => {
    getUserDetails();
    getCartDetails();
    getSiteConfig();
    getProducts();
  }, []);

  const getSiteConfig = () => {
    fetch(`${apiUrl}/api/siteupdate.php?action=fetchsiteconfig`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then(async (response) => {
        setSiteconfig(response.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProducts = () => {
    fetch(`${apiUrl}/api/products.php?action=fetch`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setProducts(res.products);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const manageCart = async (action, itemId, itemName, price, picture) => {
    let cartContent = cart.slice();
    let item = cartContent.filter((a) => a.id === itemId);

    if (action === "+") {
      if (item.length > 0) {
        let newQty = item[0].qty + 1;
        item[0].qty = newQty;
        item[0].price = price;
        item[0].total = parseFloat(newQty * price).toFixed(2);
      } else {
        let newItem = {
          id: itemId,
          item: itemName,
          qty: 1,
          price: price,
          total: price,
        };
        cartContent.push(newItem);
      }
      await setCart(cartContent);
      await localStorage.setItem("cartitems", JSON.stringify(cartContent));
    } else if (action === "-") {
      if (item.length > 0) {
        if (item[0].qty > 1) {
          let newQty = item[0].qty - 1;
          item[0].qty = newQty;
          item[0].price = price;
          item[0].total = parseFloat(newQty * price).toFixed(2);
        }
      }
      await setCart(cartContent);
      await localStorage.setItem("cartitems", JSON.stringify(cartContent));
    } else if (action === "delete") {
      if (item.length > 0) {
        let items = cartContent.filter((p) => p.id !== itemId);
        await setCart(items);
        await localStorage.removeItem("cartitems");
      }
    } else if (action === "clear") {
      await setCart([]);
      await localStorage.removeItem("cartitems");
    }
  };

  const getOrderTotal = () => {
    const cartItems = cart.slice();
    const total = cartItems.reduce((a, b) => a + parseFloat(b.total), 0);

    if (total > 0) {
      return parseFloat(total).toFixed(2);
    } else {
      return 0.0;
    }
  };

  const getOrderItemsTotal = () => {
    const cartItems = cart.slice();
    const total = cartItems.reduce((a, b) => a + b.qty, 0);

    if (total > 0) {
      return total;
    } else {
      return "";
    }
  };

  const formatAmount = (price) => {
    if (price > 0) {
      const formatter = Intl.NumberFormat("en", { notation: "compact" });
      return `N${formatter.format(price)}`;
    } else {
      return `N0.00`;
    }
  };

  const formatTotalAmount = (price) => {
    if (price > 0) {
      const formatter = Intl.NumberFormat("en", { notation: "compact" });
      return `N${formatter.format(price)}`;
    } else {
      return `N0.00`;
    }
  };

  // const formatBalance = (price) => {
  //   let num;

  //   if (price > 0) {
  //     num = price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  //     return `N${num}`;
  //   } else {
  //     return `N0.00`;
  //   }
  // };

  const formatBalance = (price) => {
    let num, thesign, thenumber;

    thesign = price < 0 ? "-" : "";
    thenumber = price < 0 ? price * -1 : price;

    if (thenumber > 0) {
      num = thenumber.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return price < 0 ? `N${num}.00` : `N${num}`;
    } else {
      return `N0.00`;
    }
  };

  const formatAmountK = (price) => {
    if (price > 0) {
      let num, numprice;

      numprice = price / 1000;
      num = `${numprice.toString()}`;

      return `${num}k`;
    } else {
      return "-";
    }
  };
  const slugify = (string) => {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  };

  const handleAvailability = (id) => {
    fetch(`${apiUrl}/api/users.php?action=changeavailability&id=${id}`, {
      header: { "Content-Type": "application/json" },
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then(async (response) => {
        const storedUser = JSON.stringify(response.user);

        if (storedUser) {
          setUserData(response.user);
          await localStorage.setItem("user", storedUser);
        } else {
          setMsg(response.msg);
          setTimeout(() => {
            setMsg("");
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserDetails = async () => {
    const fromStorageUser = await localStorage.getItem("user");
    const parsedVUser = JSON.parse(fromStorageUser);
    if (fromStorageUser !== null) {
      await setUserData(parsedVUser);
      await setIsLoggedOn(true);
    }
  };

  const getCartDetails = async () => {
    const fromStorageCart = await localStorage.getItem("cartitems");
    const parsedVCart = JSON.parse(fromStorageCart);
    if (fromStorageCart !== null) {
      await setCart(parsedVCart);
    }
  };

  const handleSignOut = async () => {
    await setUserData(null);
    await setIsLoggedOn(false);
    await localStorage.removeItem("user");
    await localStorage.removeItem("loggedin", false);
  };

  const handleSignIn = async (data) => {
    const storedUser = JSON.stringify(data);

    await setUserData(data);
    await setIsLoggedOn(true);
    await localStorage.setItem("user", storedUser);
    await localStorage.setItem("loggedin", true);
  };

  const contextValue = {
    apiUrl,
    items,
    categories,
    cart,
    msg,
    isLoggedOn,
    userData,
    userToken,
    orderTotal,
    siteconfig,
    products,
    isMobile,
    singleProduct,
    slugify,
    getOrderTotal,
    setItems,
    manageCart,
    handleSignOut,
    handleSignIn,
    setUserData,
    handleAvailability,
    formatAmount,
    formatTotalAmount,
    formatAmountK,
    formatBalance,
    getOrderItemsTotal,
  };

  return (
    <SiteContext.Provider value={contextValue}>{children}</SiteContext.Provider>
  );
};
