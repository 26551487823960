import React, {useState, useContext} from 'react';
import { Header } from '.';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import moment from 'moment';
import { Formik, Field } from 'formik';
import { DashboardMenu } from '../components';
import { SiteContext } from '../context';
import * as Yup from 'yup';
import DatePicker from "react-datepicker";  
import "react-datepicker/dist/react-datepicker.css";  

const validationSchema = Yup.object().shape({
    contitle: Yup.string()
      .label('Title')
      .required()
      .min(5, 'Must have at least 5 characters'),
    conabout: Yup.string()
      .label('About')
      .required(),
    conamount: Yup.string()
      .label('Amount')
      .required(),
    constatus: Yup.string()
      .label('Status')
      .required(),
  });

const ContributionAddUser = () => {
    
    let {orgref, org} = useLocation().state; 
    let navigate = useNavigate();

const { apiUrl } = useContext(SiteContext);

const userData = JSON.parse(localStorage.getItem('user'));

const [condate, setCondate] = useState(null);
const [uploadedPic, setUploadedPic] =  useState(null);
const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');

  const handleSubmit = (values, { setSubmitting, resetForm, setShowModal, selectedPackage, selectedUser }) => {

    let formData = new FormData();

    formData.append('contitle', values.contitle);
    formData.append('condesc', values.conabout);
    formData.append('conamount', values.conamount);
    formData.append('constatus', values.constatus);
    formData.append('condate', moment(condate).format('YYYY-MM-DD'));
    formData.append('conorgref', orgref);
    formData.append('conusername', userData.username);
    formData.append('role', userData.role);
    formData.append('action', 'create');
  
      return fetch(`${apiUrl}/api/contributions.php`, {
        method: 'post',
        body: formData
      })
          .then((res) => res.json())
        .then(async (res) => {

          console.log(res);
  
          if(res.status === 200) {
              resetForm({});
              setSubmitting(false);
              setMsg(res.msg);
              setTimeout(() => {
                setMsg('');
              }, 3000);
              setTimeout(() => {
                navigate('/contributionsuser', {state: {orgref, org}});
            }, 3000);
          } else if(res.status === 400) {
            setErrorMsg(res.msg);
            setTimeout(() => {
              setErrorMsg('');
            }, 5000);
            setSubmitting(false);
          } else {
            alert(
                  'Contribution submission failed',
                  'Error connection to the server please check your details and try again',
                  [{ text: 'Okay' }]
                );
          }
        })
        .catch((error) => {
          console.log('Api call error', error.message);
        });
    };
    
    return (
        <div>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                    <div className='container my-2'>

                    <div className='my-1 py-1 bg-light p-2'><Link className='px-1' to ='/'>Home</Link> <i className='bi bi-chevron-right'></i> <Link className='mx-1 px-1' to ='/organisationsuser'>Organisations</Link></div>

                <h3>Contribution Manage ({org})</h3>
                <div className='my-2 d-flex justify-content-between'>
                  <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                  </div>
                </div>

                <Formik
                    initialValues={{
                      conid: '',
                      contitle: '',
                      conabout: '',
                      conamount: '',
                      constatus: '',
                      conorgref: orgref,
                      conusername: userData.conusername,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <div>
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                        <div className='row my-2'>
                          <div className='col-md-6'>

                            <div className='form-group my-2'>
                            <div>
                              <label htmlFor="contitle">Title</label>
                            </div>
                            <Field
                              id="contitle"
                              name="contitle"
                              value={values.contitle}
                              onChangeText={handleChange('contitle')}
                              placeholder="Title"
                              placeholderTextColor="#999"
                              onBlur={handleBlur('contitle')}
                              autoCapitalize="none"
                              style={{
                                padding: 4,
                            }}
                            className="form-control"
                            />
                            {touched.contitle && errors.contitle && <div className='py-1'>
                                  <span className='text-danger'>{touched.contitle && errors.contitle}</span>
                            </div>}
                          </div>


                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="conabout">Description</label>
                          </div>
                            <Field
                              as='textarea'
                              id="conabout"
                              name="conabout"
                              value={values.conabout}
                              onChangeText={handleChange('conabout')}
                              placeholder="Description"
                              placeholderTextColor="#999"
                              onBlur={handleBlur('conabout')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            rows='3'
                            className="form-control"
                            />
                          {touched.conabout && errors.conabout && <div className='py-1'>
                                <span className='text-danger'>{touched.conabout && errors.conabout}</span>
                          </div>}
                        </div>

                          </div>
                          <div className='col-md-6'>

                          <div className='form-group my-3'>
                            <div>
                              <label htmlFor="conamount">Amount</label>
                            </div>
                            <Field
                              id="conamount"
                              name="conamount"
                              value={values.conamount}
                              onChangeText={handleChange('conamount')}
                              placeholder="Amount"
                              placeholderTextColor="#999"
                              onBlur={handleBlur('conamount')}
                              autoCapitalize="none"
                              style={{
                                padding: 4,
                            }}
                            className="form-control"
                            />
                            {touched.conamount && errors.conamount && <div className='py-1'>
                                  <span className='text-danger'>{touched.conamount && errors.conamount}</span>
                            </div>}
                          </div>

                          <div className='form-group my-2'>
                          <div>
                              <label htmlFor="condate">Date</label>
                          </div>
                          
                          <DatePicker 
                                  className='form-control' 
                                  selected={condate} 
                                  onChange={(date) => setCondate(date)}
                                  dateFormat="yyyy-MM-dd"  
                              />  
                          </div>

                          <div className='form-group my-1'>
                          <div>
                            <label htmlFor="constatus">Status</label>
                          </div>
                            <Field
                              as="select"
                              id="constatus"
                              name="constatus"
                              value={values.constatus}
                              onChangeText={handleChange('constatus')}
                              placeholder="Status"
                              placeholderTextColor="#999"
                              onBlur={handleBlur('constatus')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            >
                              <option value="">Select...</option>
                              <option value="Active">Active</option>
                              <option value="Inactive">Inactive</option>
                            </Field>

                          {touched.constatus && errors.constatus && <div className='py-1'>
                                <span className='text-danger'>{touched.constatus && errors.constatus}</span>
                          </div>}
                        </div>

                          </div>
                        </div>
                        <button
                            type='submit'
                            onClick={handleSubmit}
                            title="Submit"
                            disabled={!isValid || isSubmitting}
                            loading={isSubmitting}
                            className='btn btn-primary'
                          >
                              Submit
                          </button>

                          <button
                            onClick={() => navigate(-1)}
                            name="Cancel"
                            className='btn btn-secondary px-2 mx-2'
                          >
                              Cancel
                          </button>
                        
                        </form>
                        </div>
                    )}
                  </Formik>
                              </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ContributionAddUser;