import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Pagination } from "../components";
import { SiteContext } from "../context";

const ContributionsDashboardSingle = () => {
  let { org } = useLocation().state;
  let navigate = useNavigate();

  const { apiUrl, formatAmount, formatAmountK } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [members, setMembers] = useState([]);
  const [membercontribs, setMemberContribs] = useState([]);
  const [payments, setPayments] = useState([]);
  const [selectedContribution, setSelectedContribution] = useState(null);
  const [contributionPayment, setContributionPayment] = useState([]);
  const [total, setTotal] = useState(null);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(1);
  const [orgYear, setOrgYear] = useState("2023");
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getOrgMembers();
    getContributions();
    getPayments();
  }, [page, limit, orgYear]);

  const getOrgMembers = () => {
    fetch(
      `${apiUrl}/api/organisation_members.php?action=fetch&orgcon=${org.orgref}&page=${page}&limit=${limit}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            setMembers(res.rows);
            setTotal(res.count);
          }
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getContributions = () => {
    fetch(
      `${apiUrl}/api/contributions.php?action=fetchcontributions&orgcon=${org.orgref}&orgyear=${orgYear}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setMemberContribs(res.rows);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPayments = () => {
    fetch(
      `${apiUrl}/api/members_payments.php?action=fetchpayments&orgref=${org.orgref}&orgyear=${orgYear}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            setPayments(res.rows);
          }
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterPayment = (conid) => {
    let filtered = payments.filter(
      (item) => item.memconid === conid.toString()
    );

    if (filtered.length > 0) {
      setContributionPayment(filtered);
      setSelectedContribution(conid);
    }
  };

  const getContPaymentTotal = (selectedContribution) => {
    let filtered = payments.filter(
      (item) => item.memconid === selectedContribution
    );

    return filtered.reduce((a, b) => a + parseFloat(b.mempay), 0).toFixed(2);
  };

  const getPercentage = (amount, contid) => {
    let expectedTotal = members.length * amount;
    let percentage = "0%";

    if (members.length > 0) {
      percentage = (
        (getContPaymentTotal(contid) * 100) /
        expectedTotal
      ).toFixed();

      let total = percentage > 100 ? 100 : percentage;
      return `${percentage}%`;
    }

    return percentage;
  };

  const doPrevious = async () => {
    if (parseInt(page) > 1) {
      await setPage(() => parseInt(page - 1));
      setCangoBack(true);
      setCangoForward(true);
    } else {
      setCangoBack(false);
      setCangoForward(true);
    }
  };

  const doNext = async () => {
    if (parseInt(page * limit) < total) {
      await setPage(() => parseInt(page + 1));
      setCangoForward(true);
      setCangoBack(true);
    } else {
      setCangoForward(false);
      setCangoBack(true);
    }
  };

  const handleChange = (event) => {
    let newLimit = event.target.value;
    setLimit(newLimit);
  };

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="my-2">
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  <div className="p-2">
                    {errorMsg !== "" ? (
                      <span className="text-danger">{errorMsg}</span>
                    ) : null}{" "}
                    {msg !== "" ? (
                      <span className="text-dark">{msg}</span>
                    ) : null}
                  </div>
                </div>
              </div>


              <div className="row d-flex justify-content-start align-content-start">
                <div className="order-2 order-md-2 col-md-7">
                  <div className="row h6">
                    <div className="col-1">{selectedContribution && "No"}</div>
                    <div className="col-9">
                      {selectedContribution && "Name"}
                    </div>
                    <div className="col-2">{selectedContribution && "N"}</div>
                  </div>
                  {contributionPayment.map((pay, i) => {
                    return (
                      <div key={i} className="border-bottom py-1">
                        <div className="row">
                          <div className="col-1">{++i}.</div>
                          <div className="col-9">{pay.memname}</div>
                          <div className="col-2">
                            {formatAmountK(pay.mempay)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="row h4">
                    <div className="col-1"></div>
                    <div className="col-9">
                      {selectedContribution && "Total"}
                    </div>
                    <div className="col-2">
                      {selectedContribution &&
                        `N${formatAmountK(
                          getContPaymentTotal(selectedContribution)
                        )}`}
                    </div>
                  </div>
                </div>
                <div className="order-1 order-md-1 col-md-5">
                  <h4>Contributions</h4>
                  {membercontribs.map((contrib, i) => {
                    return (
                      <div key={i} className="border-bottom py-1">
                        <div className="py-1">
                          <button
                            className={
                              selectedContribution === contrib.orgconid
                                ? "bg-info text-decoration-none border-0"
                                : "text-decoration-none border-0 bg-white"
                            }
                            onClick={() => filterPayment(contrib.orgconid)}
                          >
                            {++i}. {contrib.orgcontitle} (
                            {formatAmountK(contrib.orgconamount)} per member)
                          </button>
                        </div>
                        <div className="bg-warning rounded">
                          <div
                            style={{
                              width: getPercentage(
                                contrib.orgconamount,
                                contrib.orgconid
                              ),
                              height: 30,
                            }}
                            className="bg-success p-1"
                          >
                            <span className="float-end text-white">
                              {getPercentage(
                                contrib.orgconamount,
                                contrib.orgconid
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContributionsDashboardSingle;
