import React, {useEffect} from 'react';
import { AllProducts, NavigationBar, CategoriesMenu } from '../components';


const ProductsList = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])


    return (
        <div>
            <div className='container'>
                <NavigationBar section='Products' goback={false} />

                    <AllProducts coltype={'col-md-3 col-sm-6 col-12'} />           
            </div>
        </div>
    )
}

export default ProductsList;