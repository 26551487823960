import React, { useState, useContext } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SiteContext } from '../context';

const validationSchema = Yup.object().shape({
    username: Yup.string()
      .label('Username')
      .required()
      .min(4, 'Must have at least 4 characters'),
    fullname: Yup.string()
      .label('Full name')
      .required()
      .min(5, 'Must have at least 5 characters'),
    phone: Yup.string()
      .label('Mobile phone')
      .required()
      .min(11, 'Must have at least 11 characters')
      .max(14, 'Must have a maximum of 14 characters'),
    email: Yup.string()
      .label('Email')
      .email('Enter a valid email')
      .required('Please enter a registered email'),
    address: Yup.string()
      .label('Address')
      .required()
      .min(7, 'Address must have at least 5 characters '),
    residence: Yup.string()
      .label('State')
      .required(),
    password: Yup.string()
      .label('Password')
      .required()
      .min(5, 'Password must have at least 5 characters '),
    password2: Yup.string()
      .oneOf([Yup.ref('password')], 'Confirm Password must match Password')
      .required('Confirm Password is required'),
    agree: Yup.boolean().oneOf([true], 'Please check the agreement')
  });
  
const SignupFleetOwnerForm = ({handleSubmit, setShowModal}) => {
  const [errorMsg, setErrorMsg] = useState('');

  const {userData} = useContext(SiteContext);


    return (
      <div>
        <div className='container'>

          {errorMsg !== '' ? <span className='p-2 my-2 border border-danger text-danger'>{errorMsg}</span> : null}

          <Formik
                    initialValues={{
                      id:'',
                      username:'',
                      fullname: '',
                      phone: '',
                      email: '',
                      address: '',
                      residence: '',
                      password: '',
                      password2: '',
                      userrole: '',
                      fleetid: '',
                      latitude: '',
                      longitide: '',
                      isverified: false,
                      isactive: false,
                      isavailable: false,
                      padmin: userData.name,
                      agree: false,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm, setShowModal });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                        <div className='row my-2'>
                          <div className='col-md-6'>

                            <div className='form-group my-2'>
                            <div>
                              <label htmlFor="fullname">Full name</label>
                            </div>
                            <Field
                              id="fullname"
                              name="fullname"
                              value={values.fullname}
                              placeholder="Full name"
                              onBlur={handleBlur('fullname')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                            {touched.fullname && errors.fullname && <div className='py-1'>
                                  <span className='text-danger'>{touched.fullname && errors.fullname}</span>
                            </div>}
                          </div>

                          <div className='form-group my-1'>
                            <div>
                              <label htmlFor="email">Email</label>
                            </div>
                            <Field
                              id="email"
                              name="email"
                              value={values.email}
                              placeholder="Your email"
                              onBlur={handleBlur('email')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.email && errors.email && <div className='py-1'>
                                <span className='text-danger'>{touched.email && errors.email}</span>
                          </div>}
                        </div>


                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="address">Address</label>
                          </div>
                            <Field
                              as='textarea'
                              id="address"
                              name="address"
                              value={values.address}
                              placeholder="Address"
                              onBlur={handleBlur('address')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.address && errors.address && <div className='py-1'>
                                <span className='text-danger'>{touched.address && errors.address}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="residence">State of residence</label>
                          </div>
                            <Field
                              as="select"
                              id="residence"
                              name="residence"
                              value={values.residence}
                              placeholder="State of residence"
                              onBlur={handleBlur('residence')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            >
                              <option value="">Select...</option>
                              <option value="Enugu">Enugu State</option>
                            </Field>

                          {touched.residence && errors.residence && <div className='py-1'>
                                <span className='text-danger'>{touched.residence && errors.residence}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="latitude">Latitude</label>
                          </div>
                            <Field
                              id="latitude"
                              name="latitude"
                              value={values.latitude}
                              placeholder="latitude"
                              onBlur={handleBlur('latitude')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />

                          {touched.latitude && errors.latitude && <div className='py-1'>
                                <span className='text-danger'>{touched.latitude && errors.latitude}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="longitude">Longitude</label>
                          </div>
                            <Field
                              id="longitude"
                              name="longitude"
                              value={values.longitude}
                              placeholder="longitude"
                              onBlur={handleBlur('longitude')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.longitude && errors.longitude && <div className='py-1'>
                                <span className='text-danger'>{touched.longitude && errors.longitude}</span>
                          </div>}
                        </div>

                          </div>
                          <div className='col-md-6'>
                            <div className='form-group my-1'>
                            <div>
                              <label htmlFor="phone">Phone</label>
                            </div>
                            <Field
                              id="phone"
                              name="phone"
                              value={values.phone}
                              placeholder="Phone"
                              onBlur={handleBlur('phone')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                              {touched.phone && errors.phone && <div className='py-1'>
                                  <span className='text-danger'>{touched.phone && errors.phone}</span>
                              </div>}
                            </div>


                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="username">Username</label>
                          </div>
                            <Field
                              id="username"
                              name="username"
                              value={values.username}
                              placeholder="Username"
                              onBlur={handleBlur('username')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.username && errors.username && <div className='py-1'>
                                <span className='text-danger'>{touched.username && errors.username}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="password">Password</label>
                          </div>
                            <Field
                              type="password"
                              id="password"
                              name="password"
                              value={values.password}
                              placeholder="Password"
                              onBlur={handleBlur('password')}
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          
                          {touched.password && errors.password && <div className='py-1'>
                                <span className='text-danger'>{touched.password && errors.password}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="password2">Confirm Password</label>
                          </div>
                            <Field
                              type="password"
                              id="password2"
                              name="password2"
                              value={values.password2}
                              placeholder="Confirm Password"
                              onBlur={handleBlur('password2')}
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          
                          {touched.password2 && errors.password2 && <div className='py-1'>
                                <span className='text-danger'>{touched.password2 && errors.password2}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="agree"> Terms of service</label> <Field type="checkbox" name="agree" /> {values.agree ? 'I agree' : ''}
                          </div>
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="isavailable"> Available?</label> <Field type="checkbox" name="isavailable" /> {values.isavailable ? 'Yes' : 'No'}
                          </div>
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="isverified"> Verified?</label> <Field type="checkbox" name="isverified" /> {values.isverified ? 'Yes' : 'No'}
                            </div>
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="isactive"> Active?</label> <Field type="checkbox" name="isactive" /> {values.isactive ? 'Yes' : 'No'}
                          </div>                              
                        </div>

                          </div>
                        </div>
                        <button
                            type='submit'
                            buttonType="outline"
                            onClick={handleSubmit}
                            title="Create Account"
                            disabled={!isValid || isSubmitting}
                            loading={isSubmitting}
                            className='btn btn-primary'
                          >
                              Submit
                          </button>
                        
                          </form>
                    )}
                  </Formik>
      </div>
    </div>
    )
}

export default SignupFleetOwnerForm;