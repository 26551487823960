import React, {useState, useEffect, useContext} from 'react';
import { Header } from './';
import { Pagination } from '../components';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import { DashboardMenu } from '../components';
import { SiteContext } from '../context';
import ReactModal from 'react-modal';

const OrgMembers = () => {

  let navigate = useNavigate();
  let { orgref, org } = useLocation().state;

const { apiUrl } = useContext(SiteContext);

const userData = JSON.parse(localStorage.getItem('user'));

const [members, setOrgMembers] = useState([]);
const [total, setTotal] = useState(null);
const [limit, setLimit] = useState(500);
const [page, setPage] = useState(1);
const [cangoBack, setCangoBack] = useState(true);
const [cangoForward, setCangoForward] = useState(true);
const [updateItem, setUpdateItem] = useState({});
const [itemSelected, setItemSelected] = useState('');
const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');
const [showModal, setShowModal] = useState(false);

useEffect(() => {
  getOrgMembers();
},[page,limit])

const getOrgMembers = () => {
  fetch(`${apiUrl}/api/organisation_members.php?action=fetchmembersuser&orgcon=${orgref}&role=${userData.role}&page=${page}&limit=${limit}`, {
    method:'get'
  }).then((res) => {
      return res.json()
  }).then((res) => {
    if(res.status === 200) {
      if(res.rows.length > 0) {
        setOrgMembers(res.rows);
        setTotal(res.count);      
    }
      } else if (res.status === 400) {
          setMsg(res.msg)
          setTimeout(() => {
            setMsg('')
          },3000)
      }
  }).catch((err) => {
      console.log(err)
  })
}

const doPrevious = async () => {
 
  if(parseInt(page) > 1) {
    await setPage(() => parseInt(page - 1));
    setCangoBack(true);
    setCangoForward(true);
  } else {
    setCangoBack(false);
    setCangoForward(true);
  }
}

const doNext = async () => {

  if(parseInt(page * limit) < total) { 
    await setPage(() => parseInt(page + 1));
    setCangoForward(true);
    setCangoBack(true);
  } else {
    setCangoForward(false);
    setCangoBack(true);
  }
}

const handleChange = (event) => {
  let newLimit = event.target.value;
  setLimit(newLimit);
}

  const handleAdd = () => {
    navigate('/addorgmemberuser', {state: {orgref, org}});
  }

  const handleUpdate = (member) => {
    navigate('/updateorgmemberuser', {state: {member, orgref, org, 'action': 'update'}});
  }

  const handleDelete = (orgmem) => {
    setItemSelected(orgmem);
    setShowModal(true);
  }


const doDelete = (itemSelected) => {
  let formData = new FormData();

  formData.append('orgmem', itemSelected);
  formData.append('role', userData.role);
  formData.append('username', userData.username);
  formData.append('action', 'delete');

    fetch(`${apiUrl}/api/organisation_members.php`, {
      method:'post',
      body: formData
    }).then((res) => {
        return res.json()
    }).then((res) => {
      if(res.status === 200) {
        setShowModal(false);
        setMsg(res.msg)
        setTimeout(() => {
          setMsg('')
        },3000)
        getOrgMembers();
      } else if(res.status === 400) {
        setErrorMsg(res.msg)
        setTimeout(() => {
          setErrorMsg('')
        },3000)  
      }
    }).catch((err) => {
        console.log(err)
    })
  }


    return (
        <div>
            <ReactModal
      isOpen={showModal}
      style={{
        overlay: {
          flex:1,
          justifyContent: 'center',
          alignContent: 'center',
          position: 'fixed',
          top: '10%',
          bottom: '10%',
          left: '25%',
          right: '25%',
          width: '50%',
          height: 250,
          backgroundColor: 'rgba(255, 255, 255, 0.75)'
        },
        content: {
          position: 'absolute',
          top: '20px',
          left: '20px',
          right: '20px',
          bottom: '20px',
          border: '1px solid #ccc',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '20px'
        }
      }}>
        <div>
          <p className='h3 my-1 py-1'>Confirm deletion</p>
          <p className='my-1 py-2'>Action cannot be reversed!</p>
          <span className='btn btn-primary' onClick={() => doDelete(itemSelected)}>Confirm</span> <span className='btn btn-secondary' onClick={() => setShowModal(false)}>Cancel</span>
        </div>
      </ReactModal>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                    <div className='container my-2'>
                <h3>Members ({org})</h3>
                <div className='my-2 d-flex justify-content-between'>
                  <div className='align-items-center'>
                    <div className='p-2'>{errorMsg !== '' ? <span className='text-danger'>{errorMsg}</span> : null} {msg !== '' ? <span className='text-dark'>{msg}</span> : null}</div>
                  </div>
                  <div>
                    <button className='btn btn-primary mx-3' onClick={() => navigate(-1)}><i className='bi bi-chevron-back text-white'></i> Back</button> <button className='btn btn-primary' onClick={() => handleAdd()}>New Member</button>
                  </div>
                </div>

        {members.length > 0 && <Pagination limit={limit} page={page} total={total} cangoBack={cangoBack} cangoForward={cangoForward} handleChange={handleChange} doNext={doNext} doPrevious={doPrevious} />}

                {members && 
      <div className='container p-3'>
          <div className='row border p-1 bg-light'>
            <div className='col-1'>No</div>
            <div className='col-8'>Item</div>
            <div className='col-3'>Status</div>
          </div>
          {members.map((member,i) => {
            const mtype = parseInt(member.memtype) === 1 ? 'Fellow' : parseInt(member.memtype) === 2 ? 'Full Member' : parseInt(member.memtype) === 3 ? 'Associate Member' : parseInt(member.memtype) === 4 ? 'Graduate Member' : parseInt(member.memtype) === 5 ? 'Student Member' : null
            return (
              <div key={i} className='row border-bottom py-1'>
              <div className='col-1'>{++i}</div>
              <div className='col-8'><p className='h5'>{member.memname}</p>
              <i className='bi bi-phone'></i>: {member.memphone} |  <i className='bi bi-phone'></i>: {member.mememail}<br />
              Type: {mtype} | Reg: {member.memreg}<br />
              <i className='bi bi-clock'></i>: {moment(member.createdAt).format('Do MMM YYYY h:mma')}</div>
              <div className='col-3'>{member.memstatus} <p><a onClick={() => handleUpdate(member)}><i className='bi bi-pencil text-dark h4'></i></a> {userData.username !== 'testdemo' && <a onClick={() => handleDelete(member.memid)}><i className='bi bi-trash text-danger h4'></i></a>}</p></div>
            </div>  
            )
          })}
            </div>}
            </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OrgMembers;