import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Pagination } from "../components";
import { SiteContext } from "../context";
import moment from "moment";

const ContributionsDashboardExpenses = () => {
  let { org } = useLocation().state;
  let navigate = useNavigate();

  const { apiUrl, formatAmount } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [expenses, SetExpenses] = useState([]);
  const [total, setTotal] = useState(null);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(1);
  const [orgYear, setOrgYear] = useState("2023");
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getExpenses();
  }, [page, limit, orgYear]);

  const getExpenses = () => {
    fetch(
      `${apiUrl}/api/organisation_expenses.php?action=fetchexpenses&orgref=${org.orgref}&limit=${limit}&page=${page}&orgyear=${orgYear}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            SetExpenses(res.rows);
            setTotal(res.count);
          }
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getExpensesTotal = () => {
    if (expenses.length > 0) {
      let exptotal = expenses.reduce((a, b) => a + parseFloat(b.expamount), 0);
      return formatAmount(exptotal.toFixed(2));
    }
  };

  const doPrevious = async () => {
    if (parseInt(page) > 1) {
      await setPage(() => parseInt(page - 1));
      setCangoBack(true);
      setCangoForward(true);
    } else {
      setCangoBack(false);
      setCangoForward(true);
    }
  };

  const doNext = async () => {
    if (parseInt(page * limit) < total) {
      await setPage(() => parseInt(page + 1));
      setCangoForward(true);
      setCangoBack(true);
    } else {
      setCangoForward(false);
      setCangoBack(true);
    }
  };

  const handleChange = (event) => {
    let newLimit = event.target.value;
    setLimit(newLimit);
  };

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="my-2">
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  <div className="p-2">
                    {errorMsg !== "" ? (
                      <span className="text-danger">{errorMsg}</span>
                    ) : null}{" "}
                    {msg !== "" ? (
                      <span className="text-dark">{msg}</span>
                    ) : null}
                  </div>
                </div>
              </div>


              {expenses.length > 0 && (
                <Pagination
                  limit={limit}
                  page={page}
                  total={total}
                  cangoBack={cangoBack}
                  cangoForward={cangoForward}
                  handleChange={handleChange}
                  doNext={doNext}
                  doPrevious={doPrevious}
                />
              )}

              <div className="row d-flex justify-content-start align-content-start">
                <div className="col-md-12">
                  <h4>Expenses</h4>
                  <div>
                    <div className="row bg-light p-1 border">
                      <div className="col-1">No</div>
                      <div className="col-8">Item</div>
                      <div className="col-3">Amount</div>
                    </div>
                    {expenses.map((expense, i) => {
                      return (
                        <div key={i} className="row border p-1">
                          <div className="col-1">{++i}</div>
                          <div className="col-7">
                            {expense.expitem}
                            <br />
                            <span className="text-muted">
                              Date:{" "}
                              {moment(expense.expdate).format("Do MMM YYYY")}
                            </span>
                          </div>
                          <div className="col-4">
                            {formatAmount(expense.expamount)}
                          </div>
                        </div>
                      );
                    })}
                    <div className="row p-1 bg-dark text-white">
                      <div className="col-8">Total</div>
                      <div className="col-4">{getExpensesTotal()}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContributionsDashboardExpenses;
