import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SiteContext } from '../context';

const HomeProducts = ({coltype}) => {

    const { apiUrl, formatAmount } = useContext(SiteContext);
    const [items, setItems] = useState([]);
    const [msg, setMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        getProducts();
    },[])

    const getProducts = () => {
        fetch(`${apiUrl}/api/products.php?action=fetch`, {
          method:'get'
        }).then((res) => res.json()
        ).then((res) => {
            if(res.status === 200) {
                setItems(res.products)
            } else if (res.status === 400) {
                setMsg('No products found')
                setTimeout(() => {
                  setMsg('')
                },3000)
            }
        }).catch((err) => {
            console.log(err)
        })
    }
  
  

    let navigate = useNavigate();

    return (
        <div>
         {msg !== '' ? <div className='my-2'><span className='p-2 my-2 border border-info text-dark'>{msg}</span></div> : null}

        <div className='row my-3 py-3'>
          {items.map((item,i) => {
              
            return (
            <div key={i} className={`${coltype}`}>
                <button className='text-decoration-none text-dark shadow text-center border border-light bg-light rounded py-2 m-2' onClick={() => navigate(`products/detail/${item.nameslug}`)}>
                    <div className='row'>
                        <div className='p-2 d-flex justify-content-center'>
                            <img className='d-block d-md-none rounded mr-2' src={`${apiUrl}/${item.picture}`} height={150} width='70%' alt={item.name} />
                            <img className='d-none d-md-block rounded mr-2' src={`${apiUrl}/${item.picture}`} height={180} width='90%' alt={item.name} />
                        </div>
                        <span className='h5'>{item.name}</span><br />
                        <span className='h6'>{item.price !== 0 && formatAmount(item.price)}</span>
                        <div className='my-1'><button style={{'backgroundColor': 'burlywood', 'width':'90%'}} className='btn text-dark'>Details</button></div>    
                    </div>
                </button>    
            </div>
            )})
        }
        </div>
        </div>
    )
}

export default HomeProducts;