import React, { useState, useContext } from 'react';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import firebase from '../firebase';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SiteContext } from '../context/index';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const validationSchema = Yup.object().shape({
    phone: Yup.string()
        .label('Mobile phone')
        .required('Mobile phone number is required')
        .min(10, 'Cannot be less than 10 characters')
        .max(11, 'Cannot be more than 11 characters')
})

const PhoneSignup = () => {
    const { userData, isLoggedOn, apiUrl } = useContext(SiteContext);

    const [viewOtp, setViewOtp] = useState(false);
    const [confirmResult, setConfirmResult] = useState(null);
    const [pNumber, setPNumber] = useState('');
    const [msg, setMsg] = useState(null);
    const [userExist, setUserExist] = useState('No');

    const navigate = useNavigate();
    const auth = getAuth();

    const setUpRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              handleSubmit();
            }
          }, auth);   
    }


    const verifyOtp = (code) => {
        //   const code = window.prompt("Enter OTP");
        console.log(code)
        confirmResult.confirm(code).then((result) => {
        // User signed in successfully.
        const user = result.user;
        console.log('User logged in', JSON.stringify(user));
        navigate('/register', { state: { 'msg': `Successfully verified. Complete the registration process below`, 'phone': pNumber}})

        // ...
        }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        console.log(error);
        });
    
    }

    const onOtpSubmit = (event) => {
        event.preventDefault();

        const code = event.target.otp.value;
        verifyOtp(code);
    }

    const handleSubmit = (values, { setSubmitting, resetForm }) => {

        setUpRecaptcha();

        let userNumber = values.phone;
        if(userNumber.charAt(0) === '0') {
           let newNumber = userNumber.slice(1);
           userNumber = newNumber;
        }
        const phoneNumber = `+234${userNumber}`;


        fetch(`${apiUrl}/api/users.php?action=checkphone&phone=${phoneNumber}`, {
            method: 'get'
        })
        .then((res) => res.json())
        .then((response) => {

            if(response.status === 200) {
                setMsg(response.msg);
                setTimeout(() => {
                    setMsg(null);
                },5000)
                setUserExist(response.found);
                return(true);
            } else if(response.status === 400) {
                const appVerifier = window.recaptchaVerifier;
        
                signInWithPhoneNumber(auth, phoneNumber, appVerifier)
                    .then((confirmationResult) => {
                      // SMS sent. Prompt user to type the code from the message, then sign the
                      // user in with confirmationResult.confirm(code).
                      window.confirmationResult = confirmationResult;
                      // ...
                      setPNumber(phoneNumber);
                      setConfirmResult(confirmationResult);
                      setViewOtp(true);
                    }).catch((error) => {
                      // Error; SMS not sent
                      // ...
                      console.log(error);
                    });            }

        })
        .catch((err) => {
            console.log(err)
        })
       
    }

    return (
        <div>
            <Header />
            <div className='container py-3'>
                <div className='row'>
                    {!viewOtp ? <div className='container'>
                        <h4 className='my-4'>Phone sign up</h4> 

                        {msg !== null ? <div className='my-2'><span className='mb-2 py-2 text-danger'>{msg}</span></div> : null}

                        <Formik
                    initialValues={{
                      phone: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit}>
                            <div id='recaptcha-container'></div>
                            <div className='form-group'>
                                <div className='input-group'>
                                    <div name='cc' className='input-group-text input-group-prepend'>+234</div>
                                    <Field
                                        id="phone"
                                        name="phone"
                                        value={values.phone}
                                        placeholder="Enter your mobile phone number"
                                        onBlur={handleBlur('phone')}
                                        autoCapitalize="none"
                                        style={{
                                            padding: 4
                                        }}
                                        className="form-control"
                                    />
                                </div>

                                {touched.phone && errors.phone && 
                                <div className='py-1'>
                                    <span className='text-danger'>{touched.phone && errors.phone}</span>
                                </div>}

                                <button 
                                type='submit' 
                                title="Submit"
                                disabled={!isValid || isSubmitting}
                                onClick={handleSubmit} 
                                className="btn btn-primary p-2 my-2">Submit 
                                </button>
                            </div>
                          </form>
                    )}
                  </Formik>
                    </div> :
                    <div className='container'>
                    <h4>Enter OTP</h4>
                    <form onSubmit={onOtpSubmit}>
                        <div id='recaptcha-container'></div>
                        <input type='text' name='otp' id='otp' placeholder='Enter OTP' className='form-control' />
                        <button type='submit' className='btn btn-primary my-3'>Submit</button>
                    </form>
                </div>}
                </div>
            </div>
        </div>
    )
}

export default PhoneSignup;