import React from 'react';
import { SiteContextProvider } from './context';
import SiteRoutes from './navigation/SiteRoutes';

const App = () => {

  return (
    <SiteContextProvider>
      <SiteRoutes />
    </SiteContextProvider>
  );
}

export default App;
