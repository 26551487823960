import React, { useContext } from 'react';
import Carousel from "react-bootstrap/Carousel";
import { SiteContext } from '../context';
import {pic1, pic2, pic3} from '../constants'

const animationPhotos = [pic1, pic2, pic3]

const HomeSlides = () => {
  const { isMobile } = useContext(SiteContext);

      return (
        <div>
                  <Carousel
                style={{
                  backgroundColor: "black",
                  height: isMobile ? 250 : 450,
                }}
              >
                {animationPhotos.map((item,i) => {
                  return (
                    <Carousel.Item interval={3000} key={i}>
                     
                        <img
                          style={{
                            objectFit: "contain",
                            height: isMobile ? 250 : 450,
                            width: "100%",
                          }}
                          className="d-block w-100"
                          src={item}
                          height='auto'
                          width="100%"
                          alt={item}
                        />

                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                  );
                })}
              </Carousel>

            {/* <img class="d-block d-md-none img-fluid w-100" src="../home_slide.png" height={200} alt="Home slide" />
            <img class="d-none d-md-block img-fluid w-100" src="../home_slide.png" height={300} alt="Home slide" /> */}
        </div>

    )
}

export default HomeSlides;