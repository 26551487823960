import React, {useState, useEffect, useContext} from 'react';
import { Header } from '.';
import { DashboardMenu, FeedbackForm, NavigationBar, PayButton } from '../components';
import moment from 'moment';
import { Formik, Field } from 'formik';
import { useParams } from 'react-router-dom';
import { SiteContext } from '../context';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    orderstatus: Yup.string()
      .label('Status')
      .required(),
  });
  

const TransactionDetail = () => {

const { apiUrl, formatAmount, handleAvailability, singleProduct } = useContext(SiteContext);

const userData = JSON.parse(localStorage.getItem('user'));

let {orderref} = useParams();


const [order, setOrder] = useState({});
const [orderedItems, setOrderedItems] = useState([]);
const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');

useEffect(() => {
  getOrder();
},[])

const getOrder = () => {
  fetch(`${apiUrl}/api/orders.php?action=fetchsingle&orderref=${orderref}`, {
    method:'get'
  }).then((res) => {
      return res.json()
  }).then((res) => {
    if(res.status === 200) {
          setOrder(res.order);
          setOrderedItems(JSON.parse(res.order.orderdetails));
      } else if(res.status === 400){
          setMsg(res.msg);
          setTimeout(() => {
            setMsg('');
          },5000)
      }
  }).catch((err) => {
      console.log(err)
  })
}


const doCustomerUpdate = orderref => {
    fetch(`${apiUrl}/api/orders.php?action=customerupdate&user=${userData.id}&orderref=${orderref}`, {
      method:'get'
    }).then((res) => {
        return res.json()
    }).then((res) => {
      if(res.status === 200) {
        setOrder(res.orderdetail)
        setMsg(res.msg)
        setTimeout(() => {
          setMsg('')
        },3000)
      } else if(res.status === 400) {
        setErrorMsg(res.msg)
        setTimeout(() => {
          setErrorMsg('')
        },3000)  
      }
    }).catch((err) => {
        console.log(err)
    })
  }

  const doDriverUpdate = orderref => {
    fetch(`${apiUrl}/api/orders.php?action=driverupdate&user=${userData.id}&orderref=${orderref}`, {
      method:'get'
    }).then((res) => {
        return res.json()
    }).then((res) => {
      if(res.status === 200) {
        setOrder(res.orderdetail)
        setMsg(res.msg)
        setTimeout(() => {
          setMsg('')
        },3000)
      } else if(res.status === 400) {
        setErrorMsg(res.msg)
        setTimeout(() => {
          setErrorMsg('')
        },3000)  
      }
    }).catch((err) => {
        console.log(err)
    })
  }

  const doDriverPaymentUpdate = orderref => {
    fetch(`${apiUrl}/api/orders.php?action=driverpayment&user=${userData.id}&orderref=${orderref}`, {
      method:'get'
    }).then((res) => {
        return res.json()
    }).then((res) => {
      if(res.status === 200) {
        setOrder(res.orderdetail);
        setMsg(res.msg)
        if(res.orderdetail.paymentstatus === 'Paid' && res.orderdetail.driverdeliverystatus === 'Delivered') {
          handleAvailability(userData.id);
        }
        setTimeout(() => {
          setMsg('')
        },3000)  
      } else if (res.status === 400) {
        setOrder(res.orderdetail);
        setErrorMsg(res.msg)
        setTimeout(() => {
          setErrorMsg('')
        },3000)  
      }
    }).catch((err) => {
        console.log(err)
    })
  }

  const handleSubmit = (values, { setSubmitting, resetForm, setShowModal, selectedProduct, selectedUser }) => {

    let formData = new FormData();
  
    formData.append('status', values.orderstatus);
    formData.append('role', userData.role);
    formData.append('orderref', order.orderref);
    formData.append('action', 'orderupdate');
  
      return fetch(`${apiUrl}/api/orders.php`, {
        method: 'post',
        body: formData
      })
          .then((res) => res.json())
        .then(async (res) => {
  
          console.log(res);

          if(res.status === 200) {
              resetForm({});
              setSubmitting(false);
              setOrder(res.orderdetail);
              setMsg(res.msg);
              setTimeout(() => {
                setMsg('');
              }, 3000);
          } else if(res.status === 400) {
            setErrorMsg(res.msg);
            setTimeout(() => {
              setErrorMsg('');
            }, 5000);
            setSubmitting(false);
          } else {
            alert(
                  'Order placement failed',
                  'Error connection to the server please check your details and try again',
                  [{ text: 'Okay' }]
                );
          }
        })
        .catch((error) => {
          console.log('Api call error', error.message);
        });
    };
  
    const handleFeedback = (values, { setSubmitting, resetForm, setShowModal, selectedProduct, selectedUser }) => {

      let formData = new FormData();
    
      formData.append('rating', values.rating);
      formData.append('feedback', values.feedback);
      formData.append('user', userData.id);
      formData.append('orderref', order.orderref);
      formData.append('action', 'sendfeedback');
  
    
        return fetch(`${apiUrl}/api/orders.php`, {
          method: 'post',
          body: formData
        })
            .then((res) => res.json())
          .then(async (res) => {
    
            console.log(res);
            let {status, data } = res;
    
            if(res.status === 200) {
                resetForm({});
                setSubmitting(false);
                setOrder(res.orderdetail);
                setMsg(res.msg);
                setTimeout(() => {
                  setMsg('');
                }, 3000);
            } else if(res.status === 400) {
              setErrorMsg(res.msg);
              setTimeout(() => {
                setErrorMsg('');
              }, 5000);
              setSubmitting(false);
            } else {
              alert(
                    'Order placement failed',
                    'Error connection to the server please check your details and try again',
                    [{ text: 'Okay' }]
                  );
            }
          })
          .catch((error) => {
            console.log('Api call error', error.message);
          });
      };

  
    const ratingArray = (num) => {
      let narray = [];

      for(let i=1; i <= num; i++) {
        narray.push(i);
      }
      return narray;
    }

    const balanceArray = (num) => {

      const bnum = 5 - num;
      let barray = [];

      for(let i=1; i <= bnum; i++) {
        barray.push(i);
      }
      return barray;
    }

  return (
        <div>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                    <div className='container my-2'>
                      
                      <NavigationBar section={'Orders'} goback={true} />

                <h3>Order detail</h3>

                {order.hasOwnProperty('orderref')  ? 
                
                <div>
                <div className='row'>
                  <div className='col-md-6 my-3'>
                    Order Ref: {order.orderref}<br />
                    Item{orderedItems.length > 1 && 's'}: {singleProduct ? order.productname : orderedItems.map((item,i) => <div className='small' key={item.id}>{item.item} x {item.qty} {formatAmount(item.total)}</div>)}<br />
                    Total amount: {formatAmount(order.amount)}<br />
                    Order time: {moment(order.createdAt).format('Do MMM YYYY h:mma')}<br />
                  </div>
                  <div className='col-md-6 my-3'>
                    Transaction status: {order.status} {userData.role === 'admin' && <Formik
                    initialValues={{
                      orderref: order.orderref,
                      orderstatus: order.status,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                        <div className='row'>
                          <div className='col-md-6'>


                        <div className='form-group my-1'>
                          <div className='row'>
                            <div className='col-6'>
                            <Field
                              as='select'
                              id="orderstatus"
                              name="orderstatus"
                              value={values.orderstatus}
                              onChangeText={handleChange('orderstatus')}
                              placeholder="State of orderstatus"
                              placeholderTextColor="#999"
                              onBlur={handleBlur('orderstatus')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            >
                              <option value="">Select...</option>
                              <option value="New" name="New">New</option>
                              <option value="Processed" name="Processed">Processed</option>
                              <option value="Cancelled" name="Cancelled">Cancelled</option>
                              <option value="Completed" name="Completed">Completed</option>
                            </Field>

                          {touched.orderstatus && errors.orderstatus && <div className='py-1'>
                                <span className='text-danger'>{touched.orderstatus && errors.orderstatus}</span>
                          </div>}

                            </div>
                            <div className='col-6'>
                            <button
                            type='submit'
                            buttonType="outline"
                            onClick={handleSubmit}
                            title="Update"
                            disabled={!isValid || isSubmitting}
                            loading={isSubmitting}
                              className='btn btn-primary btn-sm'
                          >
                              Update
                          </button>

                            </div>
                          </div>
                        </div>

                          </div>
                        </div>
                        
                          </form>
                    )}
                  </Formik>}<br />
                    Payment status: {order.paymentstatus} 
                    {order.paymentstatus === 'Unpaid' && order.driverid === userData.id && <button className='btn-success btn-sm' onClick={() => doDriverPaymentUpdate(order.orderref)}>Confirm payment</button>}<br />
                    Payment mode: {order.paymentmode} {order.paymentmode === 'Card' && order.customerid == userData.id && order.paymentstatus === 'Unpaid' && <PayButton order={order} setOrder={setOrder} setMsg={setMsg} setErrorMsg={setErrorMsg} />}<br />
                  </div>
                  <div className='col-md-6'>
                    <h5>Customer</h5>
                    Name: {order.customername}<br />
                    Phone: {order.customerphone}<br />
                    Address: {order.customeraddress}<br />
                    Delivery: {order.customerdeliverystatus} {userData.id === order.customerid && order.customerdeliverystatus !== 'Delivered' && <button className='btn-success btn-sm' onClick={() => doCustomerUpdate(order.orderref)}>Confirm delivery</button>}<br />
                    {order.customerdeliverystatus === 'Delivered' ? <div>Delivery time: {moment(order.customerdeliverystatustime).format('Do MMM YYYY h:mma')}</div> : ''}
                  </div>
                  {order.drivername != '' && <div className='col-md-6'>
                    <h5>Driver</h5>
                    Name: {order.drivername}<br />
                    Phone: {order.driverphone}<br />
                    Delivery: {order.driverdeliverystatus}  {userData.id === order.driverid && order.driverdeliverystatus !== 'Delivered' && <button className='btn-success btn-sm' onClick={() => doDriverUpdate(order.orderref)}>Confirm delivery</button>}<br />
                    {order.driverdeliverystatus === 'Delivered' ? <div>Delivery time: {moment(order.driverdeliverystatustime).format('Do MMM YYYY h:mma')}</div> : ''}

                    {userData.id === order.customerid && order.driverfeedback === null ? <FeedbackForm handleSubmit={handleFeedback} /> :
                    <div className='my-1'>
                      <h6>Customer feedback</h6>
                    <div>Rating: {ratingArray(order.driverrating).map((icon, i) => {
                        return <span key={i}><i className='bi bi-star-fill text-warning'></i></span>
                        } 
                        )}
                      {balanceArray(order.driverrating).map((icon, i) => {
                      return <span key={i}><i className='bi bi-star'></i></span>
                      } 
                      )}
                    </div>

                    <br />
                    {order.driverfeedback}</div>}

                  </div>}
                  <div>

                  </div>
                </div>

                </div> : 
                <div>
                  <p>Order #{orderref} not found!</p>
                </div>}

            </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TransactionDetail;