import React, {useState, useEffect, useContext} from 'react';
import { Header } from '.';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { DashboardMenu, NavigationBar } from '../components';
import { SiteContext } from '../context';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    newslettertitle: Yup.string()
      .label('Title')
      .required()
      .min(3, 'Must have at least 3 characters'),
    newsletterstory: Yup.string()
      .label('Content')
      .required(),
  });

const NewsletterUpdate = () => {

    let location = useLocation(); 
    let navigate = useNavigate();

const { apiUrl } = useContext(SiteContext);

const userData = JSON.parse(localStorage.getItem('user'));

const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');

  const handleSubmit = (values, { setSubmitting, resetForm }) => {

    let formData = new FormData();
  
    formData.append('nl_id', values.newsletterid);
    formData.append('nl_subject', values.newslettertitle);
    formData.append('nl_message', values.newsletterstory);
    formData.append('nl_username', userData.username);
    formData.append('role', userData.role);
    formData.append('action', 'update');
  
      return fetch(`${apiUrl}/api/newsletters.php`, {
        method: 'post',
        body: formData
      })
          .then((res) => res.json())
        .then(async (res) => {
  
          console.log(res);
  
          if(res.status === 200) {
              resetForm({});
              setSubmitting(false);
              setMsg(res.msg);
              setTimeout(() => {
                setMsg('');
              }, 3000);
              setTimeout(() => {
                navigate('/adminnewsletters');
            }, 3000);
          } else if(res.status === 400) {
            setErrorMsg(res.msg);
            setTimeout(() => {
              setErrorMsg('');
            }, 5000);
            setSubmitting(false);
          } else {
            alert(
                  'Newsletter submission failed',
                  'Error connection to the server please check your details and try again',
                  [{ text: 'Okay' }]
                );
          }
        })
        .catch((error) => {
          console.log('Api call error', error.message);
        });
    };


    return (
        <div>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                    <div className='container my-2'>

                    <NavigationBar section={'Newsletters'} goback={true} />

                <h3>Newsletter Update</h3>
                <div className='my-2 d-flex justify-content-between'>
                  <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                  </div>
                </div>

                <Formik
                    initialValues={{
                      newsletterid: location.state.newsletter.nlb_id != '' ? location.state.newsletter.nlb_id : '',
                      newslettertitle: location.state.newsletter.nlb_title != '' ? location.state.newsletter.nlb_title : '',
                      newsletterstory: location.state.newsletter.nlb_story != '' ? location.state.newsletter.nlb_story : '',
                      newsletterusername: location.state.newsletter.nlb_postedby != '' ? location.state.newsletter.nlb_postedby : userData.username,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                        <div className='container my-2'>

                            <div className='form-group my-2'>
                            <div>
                              <label htmlFor="newslettertitle">Title</label>
                            </div>
                            <Field
                              id="newslettertitle"
                              name="newslettertitle"
                              value={values.newslettertitle}
                              placeholder="Title"
                              onBlur={handleBlur('newslettertitle')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                            {touched.newslettertitle && errors.newslettertitle && <div className='py-1'>
                                  <span className='text-danger'>{touched.newslettertitle && errors.newslettertitle}</span>
                            </div>}
                          </div>


                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="newsletterstory">Content</label>
                          </div>
                            <Field
                              as='textarea'
                              id="newsletterstory"
                              name="newsletterstory"
                              value={values.newsletterstory}
                              placeholder="Content"
                              onBlur={handleBlur('newsletterstory')}
                              autoCapitalize="none"
                              style={{
                                padding: 8
                            }}
                            className="form-control"
                            rows={3}
                            />
                          {touched.newsletterstory && errors.newsletterstory && <div className='py-1'>
                                <span className='text-danger'>{touched.newsletterstory && errors.newsletterstory}</span>
                          </div>}
                        </div>
                        </div>
                        <button
                            type='submit'
                            onClick={handleSubmit}
                            title="Submit"
                            disabled={!isValid || isSubmitting}
                            className='btn btn-primary'
                          >
                              Submit
                          </button>
                        
                        </form>
                    )}
                  </Formik>
                              </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default NewsletterUpdate;