import React, { useState, useEffect, useContext } from "react";
import { Header } from "./";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Field } from "formik";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DashboardMenu, NavigationBar } from "../components";
import { SiteContext } from "../context";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  orgcat: Yup.string()
    .label("Category")
    .required()
    .min(5, "Must have at least 5 characters"),
  orgname: Yup.string()
    .label("Name")
    .required()
    .min(5, "Must have at least 5 characters"),
  orgdesc: Yup.string().label("Description").required(),
  orgphone: Yup.string().label("Phone").required(),
  orgsubstart: Yup.string().label("Subscription from").required(),
  admin1: Yup.string().label("Admin").required(),
});

const OrgUpdate = () => {
  let org = useLocation().state.organisation;
  let navigate = useNavigate();

  let openingbalancedate =
    org.opening_bal_date !== "0000-00-00"
      ? new Date(org.opening_bal_date)
      : new Date();

  const { apiUrl, slugify } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [openingBalDate, setOpeningBalDate] = useState(openingbalancedate);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    let formData = new FormData();

    formData.append("org", values.orgid);
    formData.append("orgcat", values.orgcat);
    formData.append("orgname", values.orgname);
    formData.append("orgslug", slugify(values.orgname));
    formData.append("orgdesc", values.orgdesc);
    formData.append("orgphone", values.orgphone);
    formData.append("photo", values.photo);
    formData.append("orgemail", values.orgemail);
    formData.append("orgaddress", values.orgaddress);
    formData.append("orgstatus", values.orgstatus);
    formData.append("orgsubend", values.orgsubend);
    formData.append("orgsubstart", values.orgsubstart);
    formData.append("openingbal", values.openingbal);
    formData.append(
      "openingbaldate",
      moment(openingBalDate).format("YYYY-MM-DD")
    );
    formData.append("admin1", values.admin1);
    formData.append("admin2", values.admin2);
    formData.append("orgusername", userData.username);
    formData.append("role", userData.role);
    formData.append("action", "update");

    return fetch(`${apiUrl}/api/organisations.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
          setTimeout(() => {
            navigate("/organisations");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            "Organisation submission failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  return (
    <div>
      <Header />
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <NavigationBar section={"Organisations"} goback={true} />

              <h3>Organisation Manage</h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              <Formik
                initialValues={{
                  orgid: org.orgid != "" ? org.orgid : "",
                  orgcat: org.orgcat != "" ? org.orgcat : "",
                  orgname: org.orgname != "" ? org.orgname : "",
                  orgdesc: org.orgdesc != "" ? org.orgdesc : "",
                  orgphone: org.orgphone != "" ? org.orgphone : "",
                  photo: org.orglogo != "" ? org.orglogo : "",
                  orgemail: org.orgemail != "" ? org.orgemail : "",
                  orgaddress: org.orgaddress != "" ? org.orgaddress : "",
                  orgstatus: org.orgstatus != "" ? org.orgstatus : "",
                  orgsubstart: org.orgsubstart != "" ? org.orgsubstart : "",
                  orgsubend: org.orgsubend != "" ? org.orgsubend : "",
                  openingbal: org.opening_bal != "" ? org.opening_bal : "",
                  admin1: org.admin1 != "" ? org.admin1 : "",
                  admin2: org.admin2 != "" ? org.admin2 : "",
                  orgusername:
                    org.orgusername != "" ? org.orgusername : userData.username,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSubmit(values, { setSubmitting, resetForm });
                }}
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  isValid,
                  isSubmitting,
                  handleBlur,
                  touched,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} encype="multipart/form-data">
                    <div className="row my-2">
                      <div className="col-md-7">
                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="orgcat">Category</label>
                          </div>
                          <Field
                            as="select"
                            id="orgcat"
                            name="orgcat"
                            value={values.orgcat}
                            onChangeText={handleChange("orgcat")}
                            placeholder="Category"
                            placeholderTextColor="#999"
                            onBlur={handleBlur("orgcat")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          >
                            <option value="">Select...</option>
                            <option value="Health">Health</option>
                            <option value="Association">Association</option>
                            <option value="Group">Group</option>
                            <option value="Business">Business</option>
                            <option value="General">General</option>
                          </Field>

                          {touched.orgcat && errors.orgcat && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.orgcat && errors.orgcat}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-2">
                          <div>
                            <label htmlFor="orgname">Name</label>
                          </div>
                          <Field
                            id="orgname"
                            name="orgname"
                            value={values.orgname}
                            onChangeText={handleChange("orgname")}
                            placeholder="Name"
                            placeholderTextColor="#999"
                            onBlur={handleBlur("orgname")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          />
                          {touched.orgname && errors.orgname && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.orgname && errors.orgname}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="orgdesc">About</label>
                          </div>
                          <Field
                            as="textarea"
                            id="orgdesc"
                            name="orgdesc"
                            value={values.orgdesc}
                            onChangeText={handleChange("orgdesc")}
                            placeholder="About"
                            placeholderTextColor="#999"
                            onBlur={handleBlur("orgdesc")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            rows="4"
                            className="form-control"
                          />
                          {touched.orgdesc && errors.orgdesc && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.orgdesc && errors.orgdesc}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="orgaddress">Address</label>
                          </div>
                          <Field
                            as="textarea"
                            id="orgaddress"
                            name="orgaddress"
                            value={values.orgaddress}
                            onChangeText={handleChange("orgaddress")}
                            placeholder="Address"
                            placeholderTextColor="#999"
                            onBlur={handleBlur("orgaddress")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            rows="2"
                            className="form-control"
                          />
                          {touched.orgaddress && errors.orgaddress && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.orgaddress && errors.orgaddress}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-2">
                          <div>
                            <label htmlFor="admin1">Admin1 username</label>
                          </div>
                          <Field
                            id="admin1"
                            name="admin1"
                            value={values.admin1}
                            onChangeText={handleChange("admin1")}
                            placeholder="Admin1"
                            placeholderTextColor="#999"
                            onBlur={handleBlur("admin1")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          />
                          {touched.admin1 && errors.admin1 && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.admin1 && errors.admin1}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-2">
                          <div>
                            <label htmlFor="admin2">Admin2 username</label>
                          </div>
                          <Field
                            id="admin2"
                            name="admin2"
                            value={values.admin2}
                            onChangeText={handleChange("admin2")}
                            placeholder="Admin2"
                            placeholderTextColor="#999"
                            onBlur={handleBlur("admin2")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          />
                          {touched.admin2 && errors.admin2 && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.admin2 && errors.admin2}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group my-3">
                          <div>
                            <label htmlFor="photo"> Upload logo</label>
                          </div>
                          <input
                            id="photo"
                            name="photo"
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                "photo",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                          {org && (
                            <img
                              className="my-2"
                              src={`${apiUrl}/${org.orglogo}`}
                              height={100}
                              width={150}
                              alt="picture"
                            />
                          )}
                        </div>

                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="orgphone">Phone number</label>
                          </div>
                          <Field
                            id="orgphone"
                            name="orgphone"
                            value={values.orgphone}
                            onChangeText={handleChange("orgphone")}
                            placeholder="Phone number"
                            placeholderTextColor="#999"
                            onBlur={handleBlur("orgphone")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          />
                          {touched.orgphone && errors.orgphone && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.orgphone && errors.orgphone}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="orgemail">Email address</label>
                          </div>
                          <Field
                            id="orgemail"
                            name="orgemail"
                            value={values.orgemail}
                            onChangeText={handleChange("orgemail")}
                            placeholder="Email address"
                            placeholderTextColor="#999"
                            onBlur={handleBlur("orgemail")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          />
                          {touched.orgemail && errors.orgemail && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.orgemail && errors.orgemail}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="orgsubstart">
                              Subscription start date
                            </label>
                          </div>
                          <Field
                            id="orgsubstart"
                            name="orgsubstart"
                            value={values.orgsubstart}
                            onChangeText={handleChange("orgsubstart")}
                            placeholder="Subscription start date"
                            placeholderTextColor="#999"
                            onBlur={handleBlur("orgsubstart")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          />
                          {touched.orgsubstart && errors.orgsubstart && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.orgsubstart && errors.orgsubstart}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="orgsubend">
                              Subscription end date
                            </label>
                          </div>
                          <Field
                            id="orgsubend"
                            name="orgsubend"
                            value={values.orgsubend}
                            onChangeText={handleChange("orgsubend")}
                            placeholder="Subscription end date"
                            placeholderTextColor="#999"
                            onBlur={handleBlur("orgsubend")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          />
                          {touched.orgsubend && errors.orgsubend && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.orgsubend && errors.orgsubend}
                              </span>
                            </div>
                          )}
                        </div>

                        <div>Opening balance</div>
                        <div className="row">
                          <div className="col-6 form-group my-1">
                            <div>
                              <label htmlFor="openingbal">Amount</label>
                            </div>
                            <Field
                              id="openingbal"
                              name="openingbal"
                              value={values.openingbal}
                              onChangeText={handleChange("openingbal")}
                              placeholder="Amount"
                              placeholderTextColor="#999"
                              onBlur={handleBlur("openingbal")}
                              autoCapitalize="none"
                              style={{
                                padding: 4,
                              }}
                              className="form-control"
                            />
                            {touched.openingbal && errors.openingbal && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.openingbal && errors.openingbal}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="col-6 form-group my-1">
                            <div>
                              <label htmlFor="openingbaldate">Date</label>
                            </div>
                            <DatePicker
                              dateFormat={"yyyy-MM-dd"}
                              selected={openingBalDate}
                              onChange={(date) => {
                                setOpeningBalDate(date);
                              }}
                            />
                          </div>
                        </div>

                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="orgstatus">Status</label>
                          </div>
                          <Field
                            as="select"
                            id="orgstatus"
                            name="orgstatus"
                            value={values.orgstatus}
                            onChangeText={handleChange("orgstatus")}
                            placeholder="Status"
                            placeholderTextColor="#999"
                            onBlur={handleBlur("orgstatus")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          >
                            <option value="">Select...</option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                          </Field>

                          {touched.orgstatus && errors.orgstatus && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.orgstatus && errors.orgstatus}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      buttonType="outline"
                      onClick={handleSubmit}
                      name="Submit"
                      disabled={!isValid || isSubmitting}
                      loading={isSubmitting}
                      className="btn btn-primary"
                    >
                      Submit
                    </button>
                    <button
                      buttonType="outline"
                      onClick={() => navigate(-1)}
                      name="Cancel"
                      className="btn btn-secondary px-2 mx-2"
                    >
                      Cancel
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgUpdate;
