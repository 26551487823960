import React, {useState, useContext} from 'react';
import { Header } from '.';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { DashboardMenu } from '../components';
import { SiteContext } from '../context';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    memname: Yup.string()
      .label('Full name')
      .required()
      .min(5, 'Must have at least 5 characters'),
    memreg: Yup.string()
      .label('Registration fee')
      .required(),
    memstatus: Yup.string()
      .label('Status')
      .required(),
  });

const OrgMemberAdd = () => {

    let navigate = useNavigate();
    let { orgref, org } = useLocation().state;

const { apiUrl } = useContext(SiteContext);

const userData = JSON.parse(localStorage.getItem('user'));

const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');

  const handleSubmit = (values, { setSubmitting, resetForm }) => {

    let formData = new FormData();

    formData.append('memname', values.memname);
    formData.append('memphone', values.memphone);
    formData.append('mememail', values.mememail);
    formData.append('memtype', values.memtype);
    formData.append('memreg', values.memreg);
    formData.append('memstatus', values.memstatus);
    formData.append('memorgref', orgref);
    formData.append('memusername', userData.username);
    formData.append('role', userData.role);
    formData.append('action', 'create');
  
      return fetch(`${apiUrl}/api/organisation_members.php`, {
        method: 'post',
        body: formData
      })
          .then((res) => res.json())
        .then(async (res) => {

          console.log(res);
  
          if(res.status === 200) {
              resetForm({});
              setSubmitting(false);
              setMsg(res.msg);
              setTimeout(() => {
                setMsg('');
              }, 3000);
              setTimeout(() => {
                navigate('/orgmembersuser', {state: {orgref, org}});
            }, 3000);
          } else if(res.status === 400) {
            setErrorMsg(res.msg);
            setTimeout(() => {
              setErrorMsg('');
            }, 5000);
            setSubmitting(false);
          } else {
            alert(
                  'Member submission failed',
                  'Error connection to the server please check your details and try again',
                  [{ text: 'Okay' }]
                );
          }
        })
        .catch((error) => {
          console.log('Api call error', error.message);
        });
    };
    
    
    return (
        <div>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                    <div className='container my-2'>

                    <div className='my-1 py-1 bg-light p-2'><Link className='px-1' to ='/'>Home</Link> <i className='bi bi-chevron-right'></i> <Link className='mx-1 px-1' to ='/organisationsuser'>Organisations</Link></div>

                <h3>Member Manage ({org})</h3>
                <div className='my-2 d-flex justify-content-between'>
                  <div className='align-items-center'>
                    <div className='p-2'>{errorMsg !== '' ? <span className='text-danger'>{errorMsg}</span> : null} {msg !== '' ? <span className='text-dark'>{msg}</span> : null}</div>
                  </div>
                </div>

                <Formik
                    initialValues={{
                      memid: '',
                      memname: '',
                      memphone: '',
                      mememail: '',
                      memtype: 2,
                      memreg: '',
                      memstatus: '',
                      memusername: userData.username,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <div>
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                        <div className='row my-2'>
                          <div className='col-md-6'>

                            <div className='form-group my-2'>
                            <div>
                              <label htmlFor="memname">Full name</label>
                            </div>
                            <Field
                              id="memname"
                              name="memname"
                              value={values.memname}
                              placeholder="Full name"
                              onBlur={handleBlur('memname')}
                              autoCapitalize="none"
                              style={{
                                padding: 4,
                            }}
                            className="form-control"
                            />
                            {touched.memname && errors.memname && <div className='py-1'>
                                  <span className='text-danger'>{touched.memname && errors.memname}</span>
                            </div>}
                          </div>


                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="memphone">Phone</label>
                          </div>
                            <Field
                              id="memphone"
                              name="memphone"
                              value={values.memphone}
                              placeholder="Phone"
                              onBlur={handleBlur('memphone')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.memphone && errors.memphone && <div className='py-1'>
                                <span className='text-danger'>{touched.memphone && errors.memphone}</span>
                          </div>}
                        </div>

                        <div className='form-group my-2'>
                            <div>
                              <label htmlFor="mememail">Email address</label>
                            </div>
                            <Field
                              id="mememail"
                              name="mememail"
                              value={values.mememail}
                              placeholder="Email address"
                              onBlur={handleBlur('mememail')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.mememail && errors.mememail && <div className='py-1'>
                                <span className='text-danger'>{touched.mememail && errors.mememail}</span>
                          </div>}
                        </div>

                          </div>
                          <div className='col-md-6'>
                          <div className='form-group my-1'>
                          <div>
                            <label htmlFor="memtype">Membership type</label>
                          </div>
                            <Field
                              as="select"
                              id="memtype"
                              name="memtype"
                              value={values.memtype}
                              onChangeText={handleChange('memtype')}
                              placeholder="Status"
                              placeholderTextColor="#999"
                              onBlur={handleBlur('memtype')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            >
                              <option value="">Select...</option>
                              <option value="1">Fellow</option>
                              <option value="2">Full Member</option>
                              <option value="3">Associate Member</option>
                              <option value="4">Graduate Member</option>
                              <option value="5">Student Member</option>
                            </Field>

                          {touched.memtype && errors.memtype && <div className='py-1'>
                                <span className='text-danger'>{touched.memtype && errors.memtype}</span>
                          </div>}
                        </div>


                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="memreg">Registration</label>
                          </div>
                            <Field
                              id="memreg"
                              name="memreg"
                              value={values.memreg}
                              placeholder="Registration"
                              onBlur={handleBlur('Registration')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.memreg && errors.memreg && <div className='py-1'>
                                <span className='text-danger'>{touched.memreg && errors.memreg}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="memstatus">Status</label>
                          </div>
                            <Field
                              as="select"
                              id="memstatus"
                              name="memstatus"
                              value={values.memstatus}
                              onChangeText={handleChange('memstatus')}
                              placeholder="Status"
                              placeholderTextColor="#999"
                              onBlur={handleBlur('memstatus')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            >
                              <option value="">Select...</option>
                              <option value="Active">Active</option>
                              <option value="Inactive">Inactive</option>
                            </Field>

                          {touched.memstatus && errors.memstatus && <div className='py-1'>
                                <span className='text-danger'>{touched.memstatus && errors.memstatus}</span>
                          </div>}
                        </div>

                          </div>
                        </div>
                        <button
                            type='submit'
                            buttonType="outline"
                            onClick={handleSubmit}
                            title="Submit"
                            disabled={!isValid || isSubmitting}
                            loading={isSubmitting}
                            className='btn btn-primary'
                          >
                              Submit
                          </button>


                          <button
                            onClick={() => navigate(-1)}
                            name="Cancel"
                            className='btn btn-secondary px-2 mx-2'
                          >
                              Cancel
                          </button>

                        </form>
                        </div>
                    )}
                  </Formik>
                              </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OrgMemberAdd;