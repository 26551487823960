import React, { useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

const NavigationBar = ({section, page, goback}) => {
    let navigate = useNavigate();

    const doGoBack = () => {
        navigate(-1);
    }

    const removeDash = (d) => {
        let text = d.toString().replace(/\-+/g, ' ');
        return text;
    }

    return (
        <div className='mt-3 p-2 bg-light'>
            <Link to='/'>Home</Link> / {goback ? <button className='bg-light border-0 text-primary text-decoration-underline' onClick={() => doGoBack()}>{section}</button> : <span className='text-primary'>{section}</span>} {page ? <span> / <span style={{'text-transform':'capitalize'}}>{page.toString().replace(/\-+/g, ' ')}</span></span> : ''} 
        </div>
    )
}

export default NavigationBar;