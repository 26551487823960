import React, { useState, useEffect, useContext } from "react";
import { Header } from "./";
import { useNavigate, useLocation, Link, Outlet } from "react-router-dom";
import moment from "moment";
import { SiteContext } from "../context";

const ContributionsDashboard = () => {
  let { org } = useLocation().state;
  let navigate = useNavigate();

  const { apiUrl, formatAmount, formatBalance } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [membercontribs, setMemberContribs] = useState([]);
  const [payments, setPayments] = useState([]);
  const [expenses, SetExpenses] = useState([]);
  const [orgYear, setOrgYear] = useState("2024");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getContributions();
    getPayments();
    getExpenses();
  }, [orgYear]);

  const getContributions = () => {
    fetch(
      `${apiUrl}/api/contributions.php?action=fetchcontributionsmain&orgcon=${org.orgref}&orgyear=${orgYear}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setMemberContribs(res.rows);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPayments = () => {
    fetch(
      `${apiUrl}/api/members_payments.php?action=fetchpayments&orgref=${org.orgref}&orgyear=${orgYear}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            setPayments(res.rows);
          }
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getExpenses = () => {
    fetch(
      `${apiUrl}/api/organisation_expenses.php?action=fetchexpensesmain&orgref=${org.orgref}&orgyear=${orgYear}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            SetExpenses(res.rows);
          }
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getContPaymentGrandTotal = () => {
    if (payments.length > 0) {
      let paymenttotal = payments.reduce((a, b) => a + parseFloat(b.mempay), 0);
      return paymenttotal.toFixed(2);
    }
  };

  const getExpensesTotal = () => {
    if (expenses.length > 0) {
      let exptotal = expenses.reduce((a, b) => a + parseFloat(b.expamount), 0);
      return exptotal.toFixed(2);
    } else {
      return 0;
    }
  };

  // const getBalance = () => {
  //   let paymenttotal = payments.reduce((a, b) => a + parseFloat(b.mempay), 0);
  //   let exptotal = expenses.reduce((a, b) => a + parseFloat(b.expamount), 0);

  //   let balance =
  //     parseInt(paymenttotal) + parseInt(org.opening_bal) - parseInt(exptotal);
  //   return parseFloat(balance).toFixed(2);
  // };

  const getBalance = () => {
    let paymenttotal = payments.reduce((a, b) => a + parseFloat(b.mempay), 0);
    let exptotal = expenses.reduce((a, b) => a + parseFloat(b.expamount), 0);

    let balance = paymenttotal + parseFloat(org.opening_bal) - exptotal;
    return balance.toFixed(2);
  };

  return (
    <div>
      <Header />
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="container my-2">
              <button
                className="border-0 bg-primary rounded text-white p-2"
                onClick={() => navigate("/", { state: { org: {} } })}
              >
                Leave
              </button>

              <h3>
                {org.orgname} (
                <span className="text-muted sm">{org.orgcat}</span>)
              </h3>

              <div className="row">
                <div className="col-md-9">
                  <div className="float-start px-2">
                    <img
                      className="img-fluid rounded mr-1"
                      src={`${apiUrl}/${org.orglogo}`}
                      height={90}
                      width={90}
                      alt={org.orgname}
                    />
                  </div>
                  <span className="text-muted sm">{org.orgdesc}</span>
                  <br />
                  <span className="text-muted sm">{org.orgphone}</span>
                  <br />
                  <span className="text-muted sm">{org.orgemail}</span>
                  <br />
                </div>
                <div className="col-md-3 bg-light p-2">
      
                  <div className="row container">
                    <div className="d-flex justify-content-between">
                      <span>
                        Opening balance:
                        {org.opening_bal !== "0.00" && (
                          <p className="text-muted py-0 my-0 small">
                            as at{" "}
                            {moment(org.opening_bal_date).format(
                              "Do, MMM YYYY"
                            )}
                          </p>
                        )}
                      </span>{" "}
                      <span className="h6">
                        {formatBalance(org.opening_bal)}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Inflow:</span>{" "}
                      <span className="h6">
                        {formatBalance(getContPaymentGrandTotal())}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Outflow:</span>{" "}
                      <span className="h6">
                        {formatBalance(getExpensesTotal())}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between border-top">
                      <span>Balance:</span>{" "}
                      {getBalance() < 0 ? (
                        <span className="h6 text-danger">
                          {formatBalance(getBalance())}
                        </span>
                      ) : (
                        <span className="h6">
                          {formatBalance(getBalance())}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="m-2 p-2">
                <Link
                  to={`/contributiondashboard/${org.orgslug}`}
                  state={{ org }}
                >
                  All contributions
                </Link>
                <Link
                  className="px-2"
                  to={`/contributiondashboard/expenses/${org.orgslug}`}
                  state={{ org }}
                >
                  All expenses
                </Link>
                <Link
                  to={`/contributiondashboard/single/${org.orgslug}`}
                  state={{ org }}
                >
                  Filter by contribution
                </Link>
              </div>

              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContributionsDashboard;
