import React, {useState, useContext} from 'react';
import {Link} from 'react-router-dom';
import { NewsLetterBox } from '../components';
import ReactModal from 'react-modal';
import { SiteContext } from '../context';

const Footer = () => {
    const {siteconfig, products} = useContext(SiteContext);

    
const [itemSelected, setItemSelected] = useState({});
const [showModal, setShowModal] = useState(false);

const handleShow = (name, url) => {
    let slaccount = {
        name, url
    }
  setItemSelected(slaccount);
  setShowModal(true);
}
    return (
        <div>        
        <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex:1,
            justifyContent: 'center',
            alignContent: 'center',
            position: 'fixed',
            top: '65%',
            bottom: '10%',
            left: '5%',
            right: '5%',
            width: '90%',
            height: '25%',
            backgroundColor: 'rgba(255, 255, 255, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '10px',
            left: '10px',
            right: '10px',
            bottom: '10px',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px'
          }
        }}>
          <div className='row'>
              <div className='col-10'>
                <p className='h3 my-1 py-1'>{itemSelected.name}</p>
                <p className='my-1 py-2'>{itemSelected.url}</p>    
              </div>
              <div className='col-2'>
                  <span className='btn btn-secondary' onClick={() => setShowModal(false)}>x</span>                  
              </div>
          </div>
        </ReactModal>
    <div className='text-white' style={{'backgroundColor':'black'}}>
        <div className="container">
            <div className='row py-3'>
                <div className='col-md-4 my-1'>
                    <p className='h5'>About us</p>
                    {siteconfig.shopdescription}
                </div>
                <div className='col-md-2 my-1'>
                <p className='h5'>Products</p>
                {products.map((item,i) => {
                return <div key={i}>
                    <Link className='text-white text-decoration-none py-2' to={`/products/detail/${item.nameslug}`}>{item.name}</Link>
                    </div>
                    }
                )}
                </div>
                <div className='col-md-2 my-1'>
                    <p className='h5'>Support</p>
                    <div>
                        <Link className='text-white text-decoration-none py-2' to='/page/how-it-works'>How it works</Link>
                    </div>
                    <div>
                    <Link className='text-white text-decoration-none py-2' to='/page/feedback'>Feedback</Link>
                    </div>                    

                <p className='h5 my-1'>Follow us on</p>
                {siteconfig.slfacebook != '' && <p className='p-1 my-1'><button className='py-1' onClick={() => handleShow('facebook',siteconfig.slfacebook)}>Facebook: <i className='bi bi-facebook'></i></button></p>}
                {siteconfig.sltwitter != '' && <p className='p-1 my-1'><button className='py-1' onClick={() => handleShow('twitter',siteconfig.sltwitter)}>Twitter: <i className='bi bi-twitter'></i></button></p>}
                {siteconfig.slinstagram != '' && <p className='p-1 my-1'><button className='py-1' onClick={() => handleShow('instagram',siteconfig.slinstagram)}>Instagram: <i className='bi bi-instagram'></i></button></p>}
                {siteconfig.slyoutube != '' && <span className='p-1 my-1'><button className='py-1' onClick={() => handleShow('youtube',siteconfig.slyoutube)}>Youtube: <i className='bi bi-youtube'></i></button></span>}<br />
                </div>

                <div className='col-md-4 my-1'>
                <p className='h5'>Contact</p>
                <h6>Office Address</h6>
                <span className='p-1'><i className='bi bi-house'></i>: {siteconfig.shopaddress}<br />{siteconfig.shopstate}, {siteconfig.shopcountry}</span><br />
                <span className='p-1'><i className='bi bi-phone'></i>: {siteconfig.shopphone}</span><br />
                <span className='p-1'><i className='bi bi-envelope'></i>: {siteconfig.shopemailaddress}</span><br />
                
                <div className='my-1'><NewsLetterBox /></div>
                </div>
            </div>
        </div>
        <div className='bg-dark text-white small'>
            <div className='container'>
            <p className='py-1 font-weight-bold'>Copyright <Link className='text-white text-decoration-none' to='/'>{siteconfig.shopname}</Link> 2022 | <Link className='text-white text-decoration-none' to='/page/privacy-policy'>Privacy Policy</Link> | Email</p>
            </div>
        </div>
    </div>
    </div>
    )
}

export default Footer;