import React from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';

const AdminDashboard = () => {
    const userData = JSON.parse(localStorage.getItem('user'));

    return (
        <div>
            <Header />
            <div className='container'>
                <h2>Welcome {userData.username} to your {userData.role.toUpperCase()} dashboard</h2>
                <p className='py-1'><Link className='my-1' to='/users'>Users</Link></p>
                <p className='py-1'><Link className='my-1' to='/drivers'>Drivers</Link></p>
                <p className='py-1'><Link className='my-1' to='/packages'>Packages</Link></p>
                <p>Ratings</p>
                <p>Transactions</p>

            </div>
        </div>
    )
}

export default AdminDashboard;