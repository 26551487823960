import React, { useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import { SignupFormRegular } from '../components';
import { SiteContext } from '../context';

const Signup = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [msg, setMsg] = useState('');

    const {apiUrl} = useContext(SiteContext);
    let navigate = useNavigate();
    let location = useLocation();

    const handleSubmit = (values, { setSubmitting, resetForm }) => {

      let formData = new FormData();

      formData.append('email', values.email);
      formData.append('username', values.username);
      formData.append('name', values.fullname);
      formData.append('phone', values.phone);
      formData.append('password', values.password);
      formData.append('terms', values.agree ? 1 : 0);
      formData.append('address', values.address);
      formData.append('residence', values.residence);
      formData.append('role', 'customer');
      formData.append('action', 'createuser');

        return fetch(`${apiUrl}/api/users.php`, {
          method: 'post',
          body: formData
        })
          .then((res) => res.json())
          .then(async (res) => {
            console.log(res);
            await localStorage.clear();
    
            if(res.status === 200) {
              resetForm({});
              setSubmitting(false);
              setMsg(res.msg);
              setTimeout(() => {
                setMsg('');
                navigate('/login', {state:'Registration successful.'});
                }, 3000);
              } 
              else if(res.status === 400) {
              setErrorMsg(res.msg);
              setTimeout(() => {
                setErrorMsg('');
              }, 5000);
              setSubmitting(false);
            } else {
              alert(
                    'Signup failed',
                    'Error connection to the server please check your details and try again',
                    [{ text: 'Okay' }]
                  );
            }
          })
          .catch((error) => {
            console.log('Api call error', error.message);
          });
      };
    

    return (
      <div>
        <Header />
        <div className='container'>

        {msg !== '' ? <div className='my-4'><span className='p-2 my-2 border border-info text-dark'>{msg}</span></div> : null}
        {errorMsg !== '' ? <div className='my-4'><span className='p-2 my-2 border border-danger text-danger'>{errorMsg}</span></div> : null}

        <SignupFormRegular handleSubmit={handleSubmit} vphone={location.state} />

        <p className='my-3'><Link className='my-3 py-2 text-decoration-none h4' to='/login'>Login</Link></p>
      </div>
    </div>
    )
}

export default Signup;