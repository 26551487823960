import React, {useState, useEffect, useContext} from 'react';
import { Header } from '.';
import { TableProduct, ProductForm } from '../components';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import { AddProductModal, UpdateProductModal }  from '../modals'; 
import { DashboardMenu } from '../components';
import { SiteContext } from '../context';

const AdminProducts = () => {

const { apiUrl, slugify } = useContext(SiteContext);

const userData = JSON.parse(localStorage.getItem('user'));

const [items, setItems] = useState([]);
const [itemsCat, setItemsCat] = useState([]);
const [updateItem, setUpdateItem] = useState({});
const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');
const [showModal, setShowModal] = useState(false);
const [showUpdateModal, setShowUpdateModal] = useState(false);

useEffect(() => {
  getProducts();
  getProductsCat();
},[items])

const getProducts = () => {
  fetch(`${apiUrl}/api/products.php?action=fetch`, {
    method:'get'
    }).then((res) => {
        return res.json()
    }).then((res) => {
        if(res.status === 200) {
            setItems(res.products)      
        } else if (res.status === 400) {
            setMsg(res.msg)
            setTimeout(() => {
              setMsg('')
            },3000)
        }
    }).catch((err) => {
        console.log(err)
    })
}

const getProductsCat = () => {
  fetch(`${apiUrl}/api/productcategory.php?action=fetch`, {
    method:'get'
    }).then((res) => {
        return res.json()
    }).then((res) => {
        if(res.status === 200) {
          setItemsCat(res.categories)      
        } else if (res.status === 400) {
            setMsg(res.msg)
            setTimeout(() => {
              setMsg('')
            },3000)
        }
    }).catch((err) => {
        console.log(err)
    })
}



const handleSubmit = (values, story, { setSubmitting, resetForm, setShowModal }) => {

  let action = values.id === '' ? 'create' : 'update';
  let formData = new FormData();

  formData.append('name', values.name);
  formData.append('nameslug', slugify(values.name));
  formData.append('description', story);
  formData.append('price', values.price);
  formData.append('status', values.status ? 1 : 0);
  formData.append('picture', values.picture);
  formData.append('product', values.id);
  formData.append('action', action);

  const url = values.id === '' ? `${apiUrl}/api/products.php` : `${apiUrl}/api/products.php?id=${values.id}`;
  const sendType = 'post';

    return fetch(url, {
      method: sendType,
      body: formData
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);

        if(res.status === 200) {
            resetForm({});
            setShowModal(false);
            getProducts();
            setSubmitting(false);
            setMsg(res.msg);
            setTimeout(() => {
              setMsg('');
            }, 3000);
        } else if(res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg('');
          }, 3000);
          setSubmitting(false);
        } else {
          alert(
                'Failure',
                'Error connection to the server please check your details and try again',
                [{ text: 'Okay' }]
              );
        }
      })
      .catch((error) => {
        console.log('Api call error', error.message);
      });
  };


  const handleAdd = () => {
    setShowModal(true);
  }

  const handleUpdate = (item) => {
    setUpdateItem(item);
    setShowUpdateModal(true);
  }

const doDelete = id => {

  let formData = new FormData();

  formData.append('product', id);
  formData.append('role', userData.role);
  formData.append('action', 'delete');

    fetch(`${apiUrl}/api/products.php`, {
      method:'post',
      body: formData
    }).then((res) => {
        return res.json()
    }).then((res) => {
      if(res.status === 200) {
        getProducts();
        setMsg(res.msg)
        setTimeout(() => {
          setMsg('')
        },3000)
      } else if(res.status === 400) {
        setErrorMsg(res.msg)
        setTimeout(() => {
          setErrorMsg('')
        },3000)  
      }
    }).catch((err) => {
        console.log(err)
    })
  }


    return (
        <div>
            <AddProductModal categories={itemsCat} handleSubmit={handleSubmit} showModal={showModal} setShowModal={setShowModal} errorMsg={errorMsg} />
            <UpdateProductModal categories={itemsCat} updateItem={updateItem} handleSubmit={handleSubmit} showModal={showUpdateModal} setShowModal={setShowUpdateModal} errorMsg={errorMsg} />
          <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                    <div className='container my-2'>
                <h3>Products</h3> 

                <div className='my-2 d-flex justify-content-between'>
                  <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                  </div>
                  <div>
                    <button className='btn btn-primary' onClick={() => handleAdd()}>New product</button>
                  </div>
                </div>

                <TableProduct items={items} onDelete={doDelete} handleUpdate={handleUpdate} />
            </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AdminProducts;