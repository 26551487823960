import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { Pagination } from "../components";
import { SiteContext } from "../context";

const ContributionsDashboardAll = () => {
  let { org } = useLocation().state;
  let navigate = useNavigate();

  const { apiUrl, formatAmountK, formatTotalAmount } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [members, setMembers] = useState([]);
  const [membercontribs, setMemberContribs] = useState([]);
  const [payments, setPayments] = useState([]);
  const [expenses, SetExpenses] = useState([]);
  const [total, setTotal] = useState(null);
  const [limit, setLimit] = useState(500);
  const [page, setPage] = useState(1);
  const [orgYear, setOrgYear] = useState("2024");
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getOrgMembers();
    getContributions();
    getPayments();
  }, [page, limit, orgYear]);

  const getOrgMembers = () => {
    fetch(
      `${apiUrl}/api/organisation_members.php?action=fetch&orgcon=${org.orgref}&page=${page}&limit=${limit}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            const sorted = res.rows.sort((a, b) => {
              const nameA = a.memtype; // ignore upper and lowercase
              const nameB = b.memtype; // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // names must be equal
              return 0;
            });

            setMembers(sorted);
            setTotal(res.count);
          }
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getContributions = () => {
    fetch(
      `${apiUrl}/api/contributions.php?action=fetchcontributions&orgcon=${org.orgref}&orgyear=${orgYear}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setMemberContribs(res.rows);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPayments = () => {
    fetch(
      `${apiUrl}/api/members_payments.php?action=fetchpayments&orgref=${org.orgref}&orgyear=${orgYear}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            setPayments(res.rows);
          }
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserPayment = (name) => {
    let filtered = payments.filter((item) => item.memname === name);

    if (filtered.length > 0) {
      return filtered.map((pay, i) => (
        <td key={i} className="col border small text-center">
          {formatAmountK(pay.mempay)}
        </td>
      ));
    }
  };

  const getUserPaymentTotal = (name) => {
    let filtered = payments.filter((item) => item.memname === name);

    if (filtered.length > 0) {
      return filtered.reduce((a, b) => a + parseFloat(b.mempay), 0);
    }
  };

  const getContPaymentTotal = (id) => {
    let filtered = payments.filter((item) => item.memconid === id);

    if (filtered.length > 0) {
      return formatTotalAmount(
        filtered.reduce((a, b) => a + parseFloat(b.mempay), 0)
      );
    }
  };

  const getRegTotal = () => {
    return formatAmountK(members.reduce((a, b) => a + parseFloat(b.memreg), 0));
  };

  const getContPaymentGrandTotal = () => {
    if (payments.length > 0) {
      return formatTotalAmount(
        payments.reduce((a, b) => a + parseFloat(b.mempay), 0)
      );
    }
  };

  const getContributionTitles = () => {
    return membercontribs.map((item, i) => (
      <th
        key={i}
        style={{ textOrientation: "mixed", writingMode: "vertical-lr" }}
        className="col border p-1 text-wrap"
      >
        {item.orgcontitle.split(" ", 2).join(" ")}
      </th>
    ));
  };

  const getContributionTotal = () => {
    return membercontribs.map((item, i) => (
      <th key={i} className="col border p-1">
        {getContPaymentTotal(item.orgconid)}
      </th>
    ));
  };

  const doPrevious = async () => {
    if (parseInt(page) > 1) {
      await setPage(() => parseInt(page - 1));
      setCangoBack(true);
      setCangoForward(true);
    } else {
      setCangoBack(false);
      setCangoForward(true);
    }
  };

  const doNext = async () => {
    if (parseInt(page * limit) < total) {
      await setPage(() => parseInt(page + 1));
      setCangoForward(true);
      setCangoBack(true);
    } else {
      setCangoForward(false);
      setCangoBack(true);
    }
  };

  const handleChange = (event) => {
    let newLimit = event.target.value;
    setLimit(newLimit);
  };

  const nameBg = (id) => {
    const bg = id === 1 ? 'bg-success text-white' : id === 2 ? 'bg-light text-dark' : id === 3 ? 'bg-info text-dark' : id === 4 ? 'bg-secondary text-white' : id === 5 ? 'bg-danger text-white' : null

    return  `p-1 col-5 small ${bg}`
  }

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="my-2">
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  <div className="p-2">
                    {errorMsg !== "" ? (
                      <span className="text-danger">{errorMsg}</span>
                    ) : null}{" "}
                    {msg !== "" ? (
                      <span className="text-dark">{msg}</span>
                    ) : null}
                  </div>
                </div>
              </div>



              {members.length > 0 && (
                <Pagination
                  limit={limit}
                  page={page}
                  total={total}
                  cangoBack={cangoBack}
                  cangoForward={cangoForward}
                  handleChange={handleChange}
                  doNext={doNext}
                  doPrevious={doPrevious}
                />
              )}

              <div className="row d-flex justify-content-start align-content-start">
                <div className="col-md-12">
                  <h4>Members</h4>
                  {org && org.orgref === 'ca3591ff4' && <div className="row d-flex p-2">
                    <span className="row justify-content-start align-items-center"><span className="bg-success p-2" style={{'width': 6, 'height':6, 'marginRight':2}} />Fellows</span>
                    <span className="row justify-content-start align-items-center"><span className="bg-light border border-secondary p-2" style={{'width': 6, 'height':6, 'marginRight':2}} />Full Members</span>
                    <span className="row justify-content-start align-items-center"><span className="bg-info p-2" style={{'width': 6, 'height':6, 'marginRight':2}} />Associate Members</span>
                    <span className="row justify-content-start align-items-center"><span className="bg-secondary p-2" style={{'width': 6, 'height':6, 'marginRight':2}} />Graduate Members</span>
                    <span className="row justify-content-start align-items-center"><span className="bg-danger p-2" style={{'width': 6, 'height':6, 'marginRight':2}} />Student Members</span>
                    </div>}
                  <table className="bg-light my-3 p-2 border container">
                    <tbody>
                      <tr className="p-1">
                        <th className="p-1 col-5">Name</th>
                        {getRegTotal().length > 2 && (
                          <th
                            style={{
                              textOrientation: "mixed",
                              writingMode: "vertical-lr",
                            }}
                            className="col-1 border p-1 bg-success text-white text-wrap"
                          >
                            Registration
                          </th>
                        )}
                        {getContributionTitles()}
                        <th>Total</th>
                      </tr>
                      {members.map((member, i) => {
                        return (
                          <tr key={i} className="border p-1">
                            <td className={nameBg(parseInt(member.memtype))}>
                              {++i}. {member.memname}
                            </td>
                            {getRegTotal().length > 2 && (
                              <td className="col-1 small bg-success text-white text-center">
                                {formatAmountK(member.memreg)}
                              </td>
                            )}
                            {getUserPayment(member.memname)}
                            <td className="bg-dark small text-white text-center">
                              {formatAmountK(
                                getUserPaymentTotal(member.memname)
                              )}
                            </td>
                          </tr>
                        );
                      })}
                      <tr className="p-1 bg-dark text-white">
                        <th className="p-1 col-5">Total</th>
                        {getRegTotal().length > 2 && (
                          <th className="p-1 col-1 bg-success text-center">
                            {getRegTotal()}
                          </th>
                        )}
                        {getContributionTotal()}
                        <th className="text-center">
                          {getContPaymentGrandTotal()}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContributionsDashboardAll;
