import React, {useState, useContext} from 'react';
import { SiteContext } from '../context';
import { Link, useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';

const TableUser = ({users, onDelete, handleUpdate}) => {

  const {apiUrl, isMobile} = useContext(SiteContext);
  const [showModal, setShowModal] = useState(false);
  const [itemSelected, setItemSelected] = useState('');

  let navigate = useNavigate();

  const handleUser = (id) => {
    navigate('/userprofile', {state: id});
  }

  const handleDelete = (id) => {
    setItemSelected(id);
    setShowModal(true);
  }

  const doDelete = (id) => {
    onDelete(id);
    setItemSelected('');
    setShowModal(false);
  }

    return (
<div>
    <ReactModal
      isOpen={showModal}
      style={{
        overlay: {
          flex:1,
          justifyContent: 'center',
          alignContent: 'center',
          position: 'fixed',
          top: isMobile ? '25%' : '20%',
          bottom: isMobile ? '25%' : '30%',
          left: isMobile ? '2%' : '25%',
          right: isMobile ? '2%' : '25%',
          width: isMobile ? '96%' : '50%',
          height: isMobile ? '50%' : '50%',
          backgroundColor: 'rgba(255, 255, 255, 0.75)'
        },
        content: {
          position: 'absolute',
          top: '10px',
          left: '10px',
          right: '10px',
          bottom: '10px',
          border: '1px solid #ccc',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '20px'
        }
      }}>
        <div>
          <p className='h3 my-1 py-1'>Confirm deletion</p>
          <p className='my-1 py-2'>Action cannot be reversed!</p>
          <span className='btn btn-primary' onClick={() => doDelete(itemSelected)}>Confirm</span> <span className='btn btn-secondary' onClick={() => setShowModal(false)}>Cancel</span>
        </div>
      </ReactModal>

      <div className='container p-3'>
          <div className='row border bg-light p-2'>
            <div className='col-1'>No</div>
            <div className='col-9'>Name</div>
            <div className='col-2'>Action</div>
          </div>
          {users.map((user,i) => {
            return (
              <div key={i} className='row border-bottom p-2'>
              <div className='col-1'>{++i}</div>
              <div className='col-9'>
                <img className='rounded float-start px-2' src={`${apiUrl}/${user.photo}`} height={70} width={70} alt='photo' />
               <a onClick={() => handleUser(user.id)}><span className='text-primary'>{user.name}</span></a><br />
               <i className='bi bi-envelope'></i>: {user.email}<br />
               <i className='bi bi-phone'></i>: {user.phone}</div>
              <div className='col-2'><a onClick={() => handleUpdate(user)}><i className='bi bi-pencil-square text-dark h4'></i></a> <a onClick={() => handleDelete(user.id)}><i className='bi bi-trash text-danger h4'></i></a> </div>
            </div>  
            )
          })}</div>
      </div>
    )
}

export default TableUser;