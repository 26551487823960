import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import { SiteContext } from '../context';

const DashboardMenu = () => {
    const { isLoggedOn } = useContext(SiteContext);
    const userData = JSON.parse(localStorage.getItem('user'));

    const [showModal, setShowModal] = useState(false);


    const AdminMenu = () => {
        return (
            <div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/users'>Users</Link></div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/organisations'>Organisations</Link></div>        
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/orgmembers'>Members</Link></div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/admincontributions'>Contributions</Link></div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/adminproducts'>Products</Link></div>                        
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/transactions'>Orders</Link></div>                        
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/driverrequests'>Driver Requests</Link></div>                        
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/articles'>Articles</Link></div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/adminsiteconfig'>Site Config</Link></div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/adminfaqs'>FAQs</Link></div>             
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/adminnewsletters'>Newsletters</Link></div>                         
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/adminpages'>Site Pages</Link></div>                        
            </div>
        )
    }

    const OrgAdminMenu = () => {
        return (
            <div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/organisationsuser'>Organisations</Link></div>        
                {userData.username !== 'testdemo' && <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to="/mytransactions">Order History</Link></div>}                        
            </div>
        )
    }


    const CustomerMenu = () => {
        return (
            <div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to="/mytransactions">Order History</Link></div>
            </div>
        )
    }

    return (
        <div>
            <div className='p-3 d-block d-md-none'>
            <ReactModal
          isOpen={showModal}
          style={{
            overlay: {
              flex:1,
              justifyContent: 'center',
              alignContent: 'center',
              position: 'fixed',
              top: '2%',
              bottom: '2%',
              left: 0,
              right: 0,
              width: '100%',
              height: '96%',
              backgroundColor: 'rgba(255, 255, 255, 0.75)'
            },
            content: {
              position: 'absolute',
              top: '10px',
              left: '10px',
              right: '10px',
              bottom: '10px',
              border: '1px solid #ccc',
              background: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '10px'
            }
          }}>
            <div>
            <div className='d-flex justify-content-end'>
            <span className='btn btn-dark mb-2 py-1' onClick={() => setShowModal(false)}><i className='bi bi-x h3 text-white'></i></span>
            </div>
            <div className='p-3'>
            {isLoggedOn ? 
            <div className='text-dark'>
                <h5>Navigation</h5>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/user'>Dashboard Home</Link></div>
                {userData.role === 'admin' ? 
                <div className='my-2'><AdminMenu /></div> : 
                userData.role === 'orgadmin' ? 
                <div className='my-2'><OrgAdminMenu /></div> : 
                <div className='my-2'><CustomerMenu /></div>
                }
                <div className='py-1 border-bottom'>
                    <Link 
                    className='my-1 text-decoration-none' 
                    to="/profile">Profile</Link></div>
                <div className='py-1 border-bottom'>
                    <Link 
                    className='my-1 text-decoration-none' 
                    to="/changepassword">Change Password</Link></div>
            </div> :
            <div>
                Not logged on
            </div>}
        </div>
            </div>
          </ReactModal>
          <button className='btn-primary' onClick={() => setShowModal(true)}>Account Menu</button>
            </div>

          <div className='p-3 d-md-block d-none'>
            {isLoggedOn ? 
            <div className='text-dark'>
                <h5>Navigation</h5>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/user'>Dashboard Home</Link></div>
                {userData.role === 'admin' ? 
                <div className='my-2'><AdminMenu /></div> : 
                userData.role === 'orgadmin' ? 
                <div className='my-2'><OrgAdminMenu /></div> : 
                <div className='my-2'><CustomerMenu /></div>
                }
                {userData.username !== 'testdemo' && 
                <div className='py-1 border-bottom'>
                    <Link 
                    className='my-1 text-decoration-none' 
                    to="/profile">Profile</Link></div>}
                {userData.username !== 'testdemo' && 
                <div className='py-1 border-bottom'>
                    <Link 
                    className='my-1 text-decoration-none' 
                    to="/changepassword">Change Password</Link></div>}
            </div> :
            <div>
                Not logged on
            </div>}
        </div>
        </div>
    )
}

export default DashboardMenu;