import React, { useState, useEffect, useContext } from 'react';
import { Header } from './';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import moment from 'moment';
import { DashboardMenu } from '../components';
import { SiteContext } from '../context';
import * as Yup from 'yup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const validationSchema = Yup.object().shape({
  amount: Yup.number().required(),
  mpdate: Yup.date().required()
})

const ContributionPaymentUpdate = () => {

  let { orgref, org, contribution, contid } = useLocation().state;
  let navigate = useNavigate();

  const { apiUrl, formatAmount } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem('user'));

  const [mpdate, setMpdate] = useState(null);
  const [paymentsContributions, setPaymentsContributions] = useState([]);
  const [updated, setUpdated] = useState('');
  const [msg, setMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    getPaymentList();
  }, [contid])


  const getPaymentList = () => {
    let formData = new FormData();

    formData.append('orgref', orgref);
    formData.append('orgconid', contid);
    formData.append('role', userData.role);
    formData.append('action', 'fetchpaymentlist');

    return fetch(`${apiUrl}/api/members_payments.php`, {
      method: 'post',
      body: formData
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);

        if (res.status === 200) {
          const sorted = res.rows.sort((a, b) => {
            const nameA = a.memname; // ignore upper and lowercase
            const nameB = b.memname; // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          });


          setPaymentsContributions(sorted);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg('');
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg('');
          }, 5000);
        }
      })
      .catch((error) => {
        console.log('Api call error', error.message);
      });
  }

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    let formData = new FormData();

    formData.append('orgref', orgref);
    formData.append('orgconid', contid);
    formData.append('amount', values.amount);
    formData.append('mpname', values.mpname);
    formData.append('mpdate', values.mpdate);
    formData.append('mpid', values.mpid);
    formData.append('action', 'updateamount');

    return fetch(`${apiUrl}/api/members_payments.php`, {
      method: 'post',
      body: formData
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setMsg(res.msg);
          getPaymentList();
          setTimeout(() => {
            setMsg('');
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg('');
          }, 5000);
          setSubmitting(false)
        }
      })
      .catch((error) => {
        console.log('Api call error', error.message);
      });
  }

  return (
    <div>
      <Header />
      <div>
        <div className='row'>
          <div className='col-md-2 bg-light'>
            <DashboardMenu />
          </div>
          <div className='col-md-10'>
            <div className='container my-2'>

              <div className='my-1 py-1 bg-light p-2'><Link className='px-1' to='/'>Home</Link> <i className='bi bi-chevron-right'></i> <Link className='mx-1 px-1' to='/organisationsuser'>Organisations</Link></div>

              <h3>{org} <i className='bi bi-chevron-right'></i> {contribution}</h3>
              <div className='my-2 d-flex justify-content-between'>
                <div className='align-items-center'>
                  <div className='p-2'>{errorMsg !== '' ? <span className='text-danger'>{errorMsg}</span> : null} {msg !== '' ? <span className='text-dark'>{msg}</span> : null}</div>
                </div>

                <div className='d-flex justify-content-between'>
                  <button className='btn btn-primary mx-3' onClick={() => navigate(-1)}><i className='bi bi-chevron-back text-white'></i> Back</button>
                </div>

              </div>

              {paymentsContributions.length > 0 ?
                <div>
                  <div className='row h5 bg-light p-2'>
                    <div className='col-1'>No</div>
                    <div className='col-7'>Name</div>
                    <div className='col-4'>Update</div>
                  </div>
                  {paymentsContributions.map((item, i) => {
                    return <div key={i}>
                      <div className='row p-1 border-bottom'>
                        <div className='col-1'>{++i}</div>
                        <div className='col-7'>{item.memname}<br />
                          {formatAmount(item.mempay)}<br />
                          <span className='text-mute small'>{moment(item.mempaydate).format('Do, MMM YYYY')}</span>
                        </div>
                        <div className='col-4'>
                          <Formik
                            initialValues={{
                              mpid: item.mempayid,
                              mpname: item.memname,
                              amount: item.mempay,
                              mpdate: item.mempaydate
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                              handleSubmit(values, { setSubmitting, resetForm });
                            }}
                          >
                            {({
                              handleChange,
                              values,
                              handleSubmit,
                              errors,
                              isValid,
                              isSubmitting,
                              handleBlur,
                              touched,
                              setFieldValue
                            }) => (
                              <div>
                                <form onSubmit={handleSubmit}>

                                  <Field
                                    id="mpdate"
                                    name="mpdate"
                                    value={values.mpdate}
                                    placeholder=""
                                    onBlur={handleBlur('mpdate')}
                                    autoCapitalize="none"
                                    style={{
                                      padding: 2,
                                    }}
                                    className="form-control"
                                  />
                                  {touched.mpdate && errors.mpdate && <div className='py-1'>
                                    <span className='text-danger'>{touched.mpdate && errors.mpdate}</span>
                                  </div>}


                                  <div className='form-group'>
                                    <div className='input-group'>
                                      <Field
                                        id="amount"
                                        name="amount"
                                        value={values.amount}
                                        placeholder="0.00"
                                        onBlur={handleBlur('amount')}
                                        autoCapitalize="none"
                                        style={{
                                          padding: 2,
                                        }}
                                        className="form-control"
                                      />
                                      {touched.amount && errors.amount && <div className='py-1'>
                                        <span className='text-danger'>{touched.amount && errors.amount}</span>
                                      </div>}

                                      <div className='input-group-append'>
                                        <button
                                          type='submit'
                                          onClick={handleSubmit}
                                          title="Submit"
                                          disabled={!isValid || isSubmitting}
                                          className='btn btn-primary'
                                        >
                                          <i className='bi bi-chevron-right'></i>
                                        </button>

                                      </div>

                                    </div>
                                  </div>

                                </form>
                              </div>
                            )}
                          </Formik></div>
                      </div>
                    </div>
                  })}

                </div> :

                <div>
                  <p>No payments made for {contribution}</p>
                </div>}


            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ContributionPaymentUpdate;