import React, {useState, useEffect, useContext} from 'react';
import { Header } from './';
import { TableTransactionUser, Pagination } from '../components';
import { DashboardMenu } from '../components';
import { SiteContext } from '../context';

const TransactionsUser = () => {

const { userData, apiUrl } = useContext(SiteContext);


const [orders, setOrders] = useState([]);
const [total, setTotal] = useState(null);
const [limit, setLimit] = useState(10);
const [page, setPage] = useState(1);
const [cangoBack, setCangoBack] = useState(true);
const [cangoForward, setCangoForward] = useState(true);
const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');

useEffect(() => {
  getOrders();
},[page, limit])

const getOrders = () => {
  fetch(`${apiUrl}/api/orders.php?action=fetch&user=${userData.id}&role=${userData.role}&page=${page}&limit=${limit}`, {
    method:'get'
  }).then((res) => {
      return res.json()
  }).then((res) => {
      if(res.status === 200) {
          setOrders(res.rows);
          setTotal(res.count);      
      } else if (res.status === 400) {
          setMsg('No orders found')
          setTimeout(() => {
            setMsg('')
          },3000)
      }
  }).catch((err) => {
      console.log(err)
  })
}

const doPrevious = async () => {
 
  if(parseInt(page) > 1) {
    await setPage(() => parseInt(page - 1));
    setCangoBack(true);
    setCangoForward(true);
  } else {
    setCangoBack(false);
    setCangoForward(true);
  }
}

const doNext = async () => {

  if(parseInt(page * limit) < total) { 
    await setPage(() => parseInt(page + 1));
    setCangoForward(true);
    setCangoBack(true);
  } else {
    setCangoForward(false);
    setCangoBack(true);
  }
}

const handleChange = (event) => {
  let newLimit = event.target.value;
  setLimit(newLimit);
}


const doCancel = id => {
  let formData = new FormData();

  formData.append('id', id);
  formData.append('user', userData.id);
  formData.append('role', userData.role);
  formData.append('action', 'ordercancel');

    fetch(`${apiUrl}/api/orders.php`, {
      method:'post',
      body: formData
    }).then((res) => {
        return res.json()
    }).then((res) => {
      if(res.status === 200) {
        getOrders();
        setMsg(res.msg)
        setTimeout(() => {
          setMsg('')
        },3000)
      } else if(res.status === 400) {
        setErrorMsg(res.msg)
        setTimeout(() => {
          setErrorMsg('')
        },3000)  
      }
    }).catch((err) => {
        console.log(err)
    })
  }


    return (
        <div>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                    <div className='container my-2'>
                <h3>Orders</h3>
                <div className='my-2 d-flex justify-content-between'>
                  <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                  </div>
                </div>

                <Pagination limit={limit} page={page} total={total} cangoBack={cangoBack} cangoForward={cangoForward} handleChange={handleChange} doNext={doNext} doPrevious={doPrevious} />

                {orders && <TableTransactionUser orders={orders} onCancel={doCancel} />}
            </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TransactionsUser;