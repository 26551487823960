import React, {useState, useEffect, useContext} from 'react';
import { Header } from './';
import { Pagination, NavigationBar } from '../components';
import moment from 'moment';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { DashboardMenu } from '../components';
import { SiteContext } from '../context';
import ReactModal from 'react-modal';

const PageDetail = () => {

  let {id} = useParams();

const { apiUrl } = useContext(SiteContext);

const [sitepage, setSitepage] = useState({});
const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');

useEffect(() => {
    getPage();
},[id])

const getPage = () => {
  setErrorMsg('');
    fetch(`${apiUrl}/api/pages.php?action=fetchsingle&id=${id}`, {
        method:'get'
      }).then((res) => {
          return res.json()
      }).then((res) => {
        if(res.status === 200) {
            setSitepage(res.item);
          } else if(res.status === 400) {
            setErrorMsg(res.msg)
          }
      }).catch((err) => {
          console.log(err)
      })    
}


    return (
        <div>
            <Header />
            <div>
                <div className='container'>
                    <NavigationBar section='Page' page={sitepage.cpagename} goback={false} />

                <h3>{sitepage.cpagename}</h3>

                <div className='my-2 d-flex justify-content-between'>
                  <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                  </div>
                </div>


          {sitepage && <div className='row my-2 py-2'>
             
              <div className='col-md-8' dangerouslySetInnerHTML={{__html:sitepage.cpagecontent}} />
              {sitepage.cpagephoto != undefined && <div className='col-md-4 col-12'><img className='img-fluid' src={`${apiUrl}/${sitepage.cpagephoto}`} height={150} width='100%' alt={sitepage.cpagename} /></div>}
              </div>}

              </div>
            </div>
        </div>

    )
}

export default PageDetail;