import React, {useState, useEffect, useContext} from 'react';
import { Pagination, NavigationBar } from '../components';
import moment from 'moment';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { SiteContext } from '../context';

const Blogs = () => {

  let navigate = useNavigate();

const { apiUrl, userData } = useContext(SiteContext);

const [articles, setArticles] = useState([]);
const [total, setTotal] = useState(null);
const [limit, setLimit] = useState(10);
const [page, setPage] = useState(1);
const [cangoBack, setCangoBack] = useState(true);
const [cangoForward, setCangoForward] = useState(true);
const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');

useEffect(() => {
  getArticles();
},[page,limit])

const getArticles = () => {
  fetch(`${apiUrl}/api/articles.php?action=fetchblog&page=${page}&limit=${limit}`, {
    method:'get'
  }).then((res) => {
      return res.json()
  }).then((res) => {
    if(res.status === 200) {
      if(res.rows.length > 0) {
        setArticles(res.rows);
        setTotal(res.count);  
    }
      } else if (res.status === 400) {
          setMsg(res.msg)
          setTimeout(() => {
            setMsg('')
          },3000)
      }
  }).catch((err) => {
      console.log(err)
  })
}

const doPrevious = async () => {
 
  if(parseInt(page) > 1) {
    await setPage(() => parseInt(page - 1));
    setCangoBack(true);
    setCangoForward(true);
  } else {
    setCangoBack(false);
    setCangoForward(true);
  }
}

const doNext = async () => {

  if(parseInt(page * limit) < total) { 
    await setPage(() => parseInt(page + 1));
    setCangoForward(true);
    setCangoBack(true);
  } else {
    setCangoForward(false);
    setCangoBack(true);
  }
}

const handleChange = (event) => {
  let newLimit = event.target.value;
  setLimit(newLimit);
}


    return (
        <div>
                <div className='my-2'>
                    <NavigationBar section={'Blog'} goback={false} />

                {articles ? 
                    <div className='row'>
                        {articles.map((article,i) => {
            return (
                <div className='col-md-6 p-2 text-center'>
              <Link className='text-decoration-none text-dark text-left' to={`post/${article.titleslug}`}>
                  <div className='my-1 py-2 border rounded'>
                    <img src={`${apiUrl}/${article.artphoto}`} height={250} width={280} alt="article" /><br />
                    <h4>{article.title}</h4>
                    <span className='small text-left'> <i className='bi bi-person-circle'></i>{article.author} | <i className='bi bi-clock'></i>: {moment(article.createdAt).format('Do MMM YYYY h:mma')}</span>
                  </div></Link>
                </div>)
              })}

        {articles.length > 0 && <Pagination limit={limit} page={page} total={total} cangoBack={cangoBack} cangoForward={cangoForward} handleChange={handleChange} doNext={doNext} doPrevious={doPrevious} />}

          </div>
       : <div className='my-2'>No articles found</div>}
              </div>
        </div>
    )
}

export default Blogs;