import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SiteContext } from '../context';

const validationSchema = Yup.object().shape({
    search: Yup.string()
        .label('Search')
        .required('Cannot be empty')
        .min(3, 'Should be at least 3 characters')
})

const SearchBox = ({setShowModalSearch}) => {

    let navigate = useNavigate();

    const { apiUrl, formatAmount } = useContext(SiteContext);

    const [items, setItems] = useState([]);
    const [msg, setMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const handleSubmit = (values, { setSubmitting, resetForm, setShowModalSearch }) => {

        let formData = new FormData();
    
        formData.append('search', values.search);
        formData.append('action', 'search');
      
          return fetch(`${apiUrl}/api/search.php`, {
            method: 'post',
            body: formData
          })
              .then((res) => res.json())
            .then(async (res) => {
              console.log(res);
      
              if(res.status === 200) {
                  resetForm({});
                  setSubmitting(false);
                  setMsg(res.msg);
                  setItems(res.results);
                  setTimeout(() => {
                    setMsg('');
                  }, 3000);
              } else if(res.status === 400) {
                setErrorMsg(res.msg);
                setTimeout(() => {
                  setErrorMsg('');
                }, 5000);
                setSubmitting(false);
              } else {
                alert(
                      'Problem connecting to the server',
                      'Error connection to the server please check your details and try again',
                      [{ text: 'Okay' }]
                    );
              }
            })
            .catch((error) => {
              console.log('Api call error', error.message);
            });
        };
        
        
    
  
    return (
        <div className='container'>
        <div className='my-1 py-2'>
            <h4>Search</h4>

              <div>
              <Formik
                    initialValues={{
                      search: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm, setShowModalSearch }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                      setTimeout(() => {
                        setShowModalSearch(false);
                      },1000);
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit}>
                          <div className='form-group'>
                            <div class="input-group">
                            <Field
                              id="search"
                              name="search"
                              value={values.search}
                              placeholder="Enter your search"
                              onBlur={handleBlur('search')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />

                          <div class="input-group-append">
                             <button type='submit' 
                              title="Search"
                              disabled={!isValid || isSubmitting}
                              onClick={handleSubmit} 
                              className="input-group-text p-2"><i className="bi bi-search p-2"></i> 
                              </button>
                              </div>
                                </div>
                                {touched.search && errors.search && <div className='py-1'>
                                <span className='text-danger'>{touched.search && errors.search}</span>
                          </div>}

                            </div>
                          </form>
                    )}
                  </Formik>
        </div>

        {msg !== '' ? <div className='my-2'><span className='p-2 my-2 border border-info text-dark'>{msg}</span></div> : null}

        {items && items.length > 0 && <div>
            <h3>{items.length} result{items.length > 1 && 's'} found</h3>
        
        <div className='row'>
            {items.map((item) => {
                return (<div className='w-75 my-1 py-1 border-bottom' key={item.id}>

                <Link className='text-decoration-none text-dark text-left border border-light bg-light rounded py-2 m-2' to={(`/products/detail/${item.nameslug}`)}>
                    <div className='row'>
                      <div className='col-3'>
                      <div style={{'minHeight': 130, 'maxWidth':150}}>
                              <img className='img-fluid rounded mr-1' src={`${apiUrl}/${item.picture}`} height={140} width='100%' alt={item.name} />
                        </div> 
                      </div>
                      <div className='col-9'>
                      <span className='h5'>{item.name}</span><br />
                        <span className='text-muted sm'>{item.category}</span><br />
                        <span className='h6'>{item.price !== 0 && formatAmount(item.price)}</span>                        
                      </div>
                    </div>
                </Link>    

                </div>)
            })}
        </div>
        </div>}
        </div>
        </div>
    )
}

export default SearchBox;