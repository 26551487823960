import React, { useState, useContext } from 'react';
import {Link} from 'react-router-dom';
import { SiteContext } from '../context';

const ProfileDisplay = ({user, handleUpdate}) => {
  const {apiUrl} = useContext(SiteContext);

    return (
      <div>
        <div className='container'>

            <h3 className='my-2'>Profile <span className='px-2'><button className='btn btn-primary' onClick={() => handleUpdate(user)}><i className='bi bi-pencil-square text-dark'></i></button></span></h3>

            <table className='container p-3'>
        <tbody>
            <tr className='my-1'>
              <td className='p-2'>Name</td>
              <td className='p-2'>{user.name}</td>
            </tr>  
            <tr className='my-1'>
              <td className='p-2'>Username</td>
              <td className='p-2'>{user.username}</td>
            </tr>  
            <tr className='my-1'>
              <td className='p-2'>Phone</td>
              <td className='p-2'>{user.phone}</td>
            </tr>  
            <tr className='my-1'>
              <td className='p-2'>Email</td>
              <td className='p-2'>{user.email}</td>
            </tr>  
            <tr className='my-1'>
              <td className='p-2'>Address</td>
              <td className='p-2'>{user.address}</td>
            </tr>  
            <tr className='my-1'>
              <td className='p-2'>State</td>
              <td className='p-2'>{user.residencestate}</td>
            </tr>  
        </tbody>
      </table>

      </div>
    </div>
    )
}

export default ProfileDisplay;