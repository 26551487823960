import React, { useState, useContext } from 'react';
import { ProfileDisplay, UpdateProfilePicture, UpdateProfileIdentification, DashboardMenu } from '../components';
import { UpdateProfileModal }  from '../modals';
import { Header } from '.';
import { SiteContext } from '../context';
  
const Profile = () => {

    const {apiUrl, setUserData} = useContext(SiteContext);

    const userData = JSON.parse(localStorage.getItem('user'));

    const [updateItem, setUpdateItem] = useState({});
    const [msg, setMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showUpdatePictureModal, setShowUpdatePictureModal] = useState(false);
    const [showUpdateIdentificationModal, setShowUpdateIdentificationModal] = useState(false);

  const handleSubmit = (values, { setSubmitting, resetForm, setShowModal }) => {
    
    let formData = new FormData();
  
  
    formData.append('email', values.email);
    formData.append('username', values.username);
    formData.append('name', values.fullname);
    formData.append('phone', values.phone);
    formData.append('address', values.address);
    formData.append('residence', values.residence);
    formData.append('role', values.role);
    formData.append('user', values.id);
    formData.append('action', 'updateuser');
  
 
      return fetch(`${apiUrl}/api/users.php`, {
        method: 'post',
        body: formData
      })
          .then((res) => res.json())
        .then(async (res) => {
          console.log(res);
          const storedUser = JSON.stringify(res.user);

          if(res.status === 200) {
              resetForm({});
              setMsg(res.msg);
              setShowModal(false);
              setSubmitting(false);
              setTimeout(() => {
                setMsg('');
              }, 5000);
              setUserData(res.user);
              await localStorage.setItem('user', storedUser);
          } else if(res.status === 400) {
            setErrorMsg(res.msg);
            setTimeout(() => {
              setErrorMsg('');
            }, 5000);
            setSubmitting(false);
          } else {
            alert(
                  'Update failed',
                  'Error connection to the server please check your details and try again',
                  [{ text: 'Okay' }]
                );
          }
        })
        .catch((error) => {
          console.log('Api call error', error.message);
        });
    };
  
    const handleUpdate = (user) => {
        setUpdateItem(user);
        setShowUpdateModal(true);
      }

      const handlePictureUpdate = (user) => {
        setUpdateItem(user);
        setShowUpdatePictureModal(true);
      }

      const handleIdentificationUpdate = (user) => {
        setUpdateItem(user);
        setShowUpdateIdentificationModal(true);
      }
  
    return (
      <div>
          <UpdateProfileModal user={userData} updateItem={updateItem} handleSubmit={handleSubmit} showModal={showUpdateModal} setShowModal={setShowUpdateModal} errorMsg={errorMsg} />
          <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                        <div className='container my-2'>

                        {msg !== '' ? <div className='my-4'><span className='p-2 my-2 border border-info text-dark'>{msg}</span></div> : null}
                        {errorMsg !== '' ? <div className='my-4'><span className='p-2 my-2 border border-danger text-danger'>{errorMsg}</span></div> : null}

                            <div className='row'>
                              <div className='col-md-6'>
                              <UpdateProfilePicture user={userData} handleUpdate={handlePictureUpdate} />
                              </div>
                              {(userData.role === 'driver' || userData.role === 'fleetowner') && <div className='col-md-6'>
                              <UpdateProfileIdentification user={userData} handleUpdate={handleIdentificationUpdate} />                                
                              </div>}
                            </div>
                            <ProfileDisplay user={userData} handleUpdate={handleUpdate} />
                        </div>
                    </div>    
                </div>
            </div>
       </div>
    )
}

export default Profile;