import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import { Cart, SearchBox } from '../components';
import { SiteContext } from "../context/index";

const Header = () => {
    const { isLoggedOn, handleSignOut, getOrderItemsTotal, apiUrl, siteconfig, isMobile, singleProduct  } = useContext(SiteContext);

    const userData = JSON.parse(localStorage.getItem('user'));

    const [showModal, setShowModal] = useState(false);
    const [mType, setMType] = useState('');
    

    let navigate = useNavigate();
  
    const doSignOut = () => {
        navigate('/')
        handleSignOut();
    }

    const switchModal = item => {
      if(item === 'menu') {
        setMType('menu');
      } else if(item ==='cart') {
        setMType('cart')
      } else if(item ==='search') {
        setMType('search')
      } else if(item ==='user') {
        setMType('user')
      }
      setShowModal(true);

    }

    const HeaderMenu = () => {
      return <div>
            <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/'>Home</Link></div>
            <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/products'>Products</Link></div>
            <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/faqs'>FAQs</Link></div>
            <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/user'>My Account</Link></div>
            <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/posts'>Blog</Link></div>
            <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/page/about'>About</Link></div>
            <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/contact'>Contact</Link></div>
        </div>
    }

    const UserMenu = () => {
      return <div>
        {userData ? <h4 className='py-1 border-bottom'>Hello {userData.name}</h4> :
        <h4 className='py-1 border-bottom'>Hello Guest</h4>}
        <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/user'>My Account</Link></div>
      </div>
    }

    return (
        <div>
      <ReactModal
      isOpen={showModal}
      ariaHideApp={false}
      style={{
        overlay: {
          flex:1,
          justifyContent: 'center',
          alignContent: 'center',
          position: 'fixed',
          top: isMobile ? 0 : '25%',
          bottom: isMobile ? 0 : '25%',
          left: isMobile ? 0 : '20%',
          right: isMobile ? 0 : '20%',
          width: isMobile ? '100%' : '60%',
          height: isMobile ? '100%' : '50&',
          backgroundColor: 'rgba(255, 255, 255, 0.75)'
        },
        content: {
          position: 'absolute',
          top: '10px',
          left: '10px',
          right: '10px',
          bottom: '10px',
          border: '1px solid #ccc',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '10px'
        }
      }}>
              <div>
      <div className='d-flex justify-content-end'>
      <span className='btn btn-dark mb-2 py-1' onClick={() => setShowModal(false)}><i className='bi bi-x h3 text-white'></i></span>
      </div>
          {mType === 'menu' && <HeaderMenu />} {mType === 'cart' && <Cart />}  {mType === 'search' && <SearchBox />}  {mType === 'user' && <UserMenu />}
      </div>
      </ReactModal>

            <div style={{'backgroundColor':'black'}} className='shadow'>
              <div className='text-white p-1 small' style={{'backgroundColor':'grey'}}>
              <div className='container-fluid'>
              <div className='row align-items-center'>
                  <div className='col-md-7 col-7'>
                    <div className='d-none d-md-block'>
                    <span className='pr-2'><i className='bi bi-phone'></i> {siteconfig.shopphone}</span> <span className='px-2'><i className='bi bi-envelope'></i> {siteconfig.shopemailaddress}</span>
                    </div>
                    <div className='d-block d-md-none'>
                    <span className='pr-2'><i className='bi bi-phone'></i> {siteconfig.shopphone}</span>
                    </div>
                  </div>
                  <div className='col-md-5 col-5'>
                  <div className='d-flex justify-content-end align-items-center'>
                            {
                            isLoggedOn ? 
                            <div className='py-1'>
                                        <span className='px-1'><button className='px-2 rounded-pill bg-dark text-white' onClick={() => switchModal('user')}>{userData !== null ? <span><img src={`${apiUrl}/${userData.photo}`} height={25} width={25} className='rounded-circle' alt={userData.name} /> <i className='bi bi-chevron-down h6'></i></span> : <i className='bi bi-person'></i>} </button></span> 
                                        <span className='px-1'><button className='px-2 py-1 rounded bg-dark text-white' onClick={() => doSignOut()}><i className='bi bi-box-arrow-right h6'></i></button></span> 
                             </div> : 
                            <div className='py-1'>
                                <span className='px-1'><Link to='/login' className='p-1 rounded-pill bg-dark text-decoration-none text-white'>Login</Link></span>
                                <span className='px-1'><Link to='/phonesignup' className='p-1 rounded-pill bg-light text-decoration-none text-dark'>Signup</Link></span>
                            </div>
                            }  
                        </div>

                  </div>
                  </div>
              </div>
              </div>
        <div className='container text-white'>
            <div className='row justify-content-between align-content-center'>
                <div className='col-6 d-block d-md-none py-2'>
                  <Link to='/'><img src={`${apiUrl}/${siteconfig.shoplogo}`} height={70} width={180} alt='Logo' /></Link>
                </div> 
                <div className='col-3 d-none d-md-block py-2'>
                    <Link to='/'><img src={`${apiUrl}/${siteconfig.shoplogo}`} height={80} width={220} alt='Logo' /></Link>
                </div> 
                <div className='col-6 d-block d-md-none pt-4'>
                    <div className='d-flex justify-content-end'>
                        <div className='d-block d-md-none'>
                            <button className='px-1 text-decoration-none btn btn-white text-white' onClick={() => switchModal('search')}><i className='bi bi-search h3'></i></button> 
                            {!singleProduct && <button className='px-2 text-decoration-none btn btn-white text-white' onClick={() => switchModal('cart')}><i className='bi bi-cart3 h3'></i><span style={{'position':'relative', 'top':-15, 'right':7}} className='text-white badge bg-danger p-1 m-0 rounded-circle small'>{getOrderItemsTotal()}</span></button>} 
                            <span className='px-2'><button className='px-2 text-decoration-none btn btn-white text-white' onClick={() => switchModal('menu')}><i className='bi bi-list text-white h3'></i></button></span> 
                        </div>
                    </div>
                </div>   
                <div className='col-9 d-none d-md-block pt-4'>
                    <div className='d-flex justify-content-end'>
                        <div className='d-none d-md-block'>
                            <Link className='px-2 text-decoration-none text-white' to="/">HOME</Link> 
                            <Link className='px-2 text-decoration-none text-white' to="/page/about">ABOUT</Link> 
                            <Link className='px-2 text-decoration-none text-white' to="/products">PRODUCTS</Link> 
                            <Link className='px-2 text-decoration-none text-white' to="/faqs">FAQs</Link> 
                            <Link className='px-2 text-decoration-none text-white' to="/posts">BLOG</Link> 
                            <Link className='px-2 text-decoration-none text-white' to="/contact">CONTACT</Link> 
                            <button className='px-2 text-decoration-none btn btn-white btn-sm text-white' onClick={() => switchModal('search')}><i className='bi bi-search h3'></i></button> 
                            {!singleProduct && <button style={{'position':'static'}} type='button' className='px-2 text-decoration-none btn btn-white btn-sm text-white' onClick={() => switchModal('cart')}><i className='bi bi-cart3 h3'></i><span style={{'position':'relative', 'top':-15, 'right':7}} className='text-white badge bg-danger p-1 m-0 rounded-circle small'>{getOrderItemsTotal()}</span></button>} 
                        </div>
                    </div>
                </div>   
            </div>
        </div>
    </div>
</div>

    )
}

export default Header;