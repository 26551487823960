import React from 'react';
import Header from './Header';

const AboutUs = () => {

    return (
        <div>
            <Header />
            <div className='container py-3'>
                About THE UYAI

            </div>
        </div>
    )
}

export default AboutUs;