import React from 'react';
import Header from './Header';
import { Link, useLocation } from 'react-router-dom';

const Register = () => {

    let msg = useLocation().state.msg;
    let phone = useLocation().state.phone;

    return (
        <div>
            <Header />
            <div className='container'>
                <div className='my-3'>
                    <h4>Phone verifcation successful</h4>
                    <p>{msg} - {phone}</p>
                </div>

                <h2 className='my-3'>Sign up as...</h2>
                <div className='row p-3'>
                    <div className='col-md-6'>
                        <p className='h3 mt-3'><Link to={'/signup'} state={phone} className='text-decoration-none'>Customer</Link></p>
                    </div>
                    <div className='col-md-6'>
                        <p className='h3 mt-3'><Link to='/signup/driver' state={phone} className='text-decoration-none'>Driver</Link></p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Register;