import React, { useContext, useState, useEffect } from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';
import { SiteContext } from "../context/index";
import DashboardMenu from '../components/DashboardMenu';

const UserDashboard = () => {
    const { apiUrl,  msg, handleAvailability  } = useContext(SiteContext);

    const userData = JSON.parse(localStorage.getItem('user'));

    const [totalStats, setTotalStats] = useState([]);

    useEffect(() => {
        getTotalStats();
    },[])

    
const getTotalStats = () => {
    fetch(`${apiUrl}/api/stats.php?action=fetch`, {
      method:'get'
    }).then((res) => res.json()
    ).then((res) => {
        if(res.status === 200) {
            setTotalStats(res.roles)
        }
    }).catch((err) => {
        console.log(err)
    })
}


    return (
        <div>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                        <h2 className='my-2 p-3'>Welcome {userData.username} to your {userData.role} dashboard</h2>
                       
                       {msg != '' ? <span className='my-2'>{msg}</span> : null}
                       
                        <span className='container'>
                            {userData.isavailable == 1 ? <button className='btn btn-success' onClick={() => handleAvailability(userData.id)}>Available</button> : <button className='btn btn-danger' onClick={() => handleAvailability(userData.id)}>UnAvailable</button>}
                        </span>

                      
                        {userData.role === 'admin' && 
                        totalStats.length > 0 && 
                        <div className="container row">
                            {totalStats.map((item, i) => {
                            return <div key={i} className='col-md m-2 p-3 border border-primary'>
                                <h6>Total {item.role}</h6>
                                <h4>{item.total}</h4>
                            </div>
                            })
                        }
                        </div>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserDashboard;