import React, { useContext } from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';
import { HomeProducts, SearchOrgBox, HomeSlides } from '../components';
import { SiteContext } from '../context/index';


const Home = () => {
    const { userData, isLoggedOn, isMobile  } = useContext(SiteContext);

    return (
        <div>
            <Header />
            <div className='container py-3'>
                <div className='row'>
                    <div className='order-md-1 order-2 col-md-8'>

                        <div style={{'backgroundColor': 'grey'}} className='rounded my-3 p-2 border border-light'>
                            <HomeSlides />
                        </div>

                    </div>
                    <div className='order-md-2 order-1 col-md-4'>
                        <SearchOrgBox />
                    </div>

                </div>
              </div>

              <div className='bg-info py-3 m-2'>
                  <div className='container p-3'>
                      <div className='row'>
                        <div className='col-md-6 p-3'>
                        <div className='shadow-lg bg-light rounded p-3'>
                           <h3 className='py-2'>Manage your inflows and outflows</h3>
                            <p>Record every income and expense you want captured and reported to anyone you give the access code</p>
                            <p>View all contributions, filter payments by specific contributions and all expenses</p>
                        </div>
                        </div>
                        <div className='col-md-6 p-3'>
                        <div className='shadow-lg bg-light rounded p-3'>
                            <h5 className='py-2'>Ideal for...</h5>
                            <div className='text-white'>
                                    <p className='py-2'>
                                        <span className='bg-dark text-white my-1 rounded p-2'>Associations</span> <span className='bg-dark text-white my-1 rounded p-2'>Groups</span> <span className='bg-dark text-white my-1 rounded p-2'>NGOs</span>
                                    </p>
                                    <p className='py-2'>
                                        <span className='bg-dark text-white my-1 rounded p-2'>Alumni</span> <span className='bg-dark text-white my-1 rounded p-2'>Projects</span> <span className='bg-dark text-white my-1 rounded p-2'>Estates</span>
                                    </p>
                            </div>

                            <h3 className='my-3'>Check out the demo</h3>
                            <p>Use <span className='h6'>66c443672</span> as the passcode and view the Test Group as a member of the group </p>                          
                            <p>Login with <span className='h6'>testdemo</span> as username and <span className='h6'>testdemopass</span> as password to test drive the admin section </p>                          
                        </div>      
                        </div>                                          
                      </div>
                  </div>
              </div>
              <div className='bg-light'>
                  <div className='py-1'>
                      <HomeProducts coltype={'col-md-3 col-sm-6 col-12'} />   
                  </div>
              </div>
        </div>
    )
}

export default Home;