import React, {useState, useEffect, useContext} from 'react';
import { Header } from './';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import moment from 'moment';
import { Formik, Field } from 'formik';
import { DashboardMenu } from '../components';
import { SiteContext } from '../context';
import * as Yup from 'yup';
import DatePicker from "react-datepicker";  
import "react-datepicker/dist/react-datepicker.css";  

const validationSchema = Yup.object().shape({
    exptype: Yup.string()
      .label('Type')
      .required(),
    expitem: Yup.string()
      .label('Item')
      .required()
      .min(5, 'Must have at least 5 characters'),
    expamount: Yup.string()
      .label('Amount')
      .required(),
  });

const OrgExpenseUpdate = () => {

    let { expense, orgref, org } = useLocation().state;
    let navigate = useNavigate();

const { apiUrl, slugify } = useContext(SiteContext);

const userData = JSON.parse(localStorage.getItem('user'));

const [expdate, setExpdate] = useState(null);
const [msg, setMsg] = useState('');
const [errorMsg, setErrorMsg] = useState('');

useEffect(() => {
    setExpdate(new Date(expense.expdate));
},[])

  const handleSubmit = (values, { setSubmitting, resetForm }) => {

    let formData = new FormData();
  
    formData.append('exporg', values.expid);
    formData.append('expitem', values.expitem);
    formData.append('exptype', values.exptype);
    formData.append('expamount', values.expamount);
    formData.append('expdate', moment(expdate).format('YYYY-MM-DD'));
    formData.append('exporgref', orgref);
    formData.append('expusername', userData.username);
    formData.append('role', userData.role);
    formData.append('action', 'update');
  
      return fetch(`${apiUrl}/api/organisation_expenses.php`, {
        method: 'post',
        body: formData
      })
          .then((res) => res.json())
        .then(async (res) => {
  
          if(res.status === 200) {
              resetForm({});
              setSubmitting(false);
              setMsg(res.msg);
              setTimeout(() => {
                setMsg('');
              }, 3000);
              setTimeout(() => {
                navigate('/orgexpensesuser', {state: {orgref, org}});
            }, 3000);
          } else if(res.status === 400) {
            setErrorMsg(res.msg);
            setTimeout(() => {
              setErrorMsg('');
            }, 5000);
            setSubmitting(false);
          } else {
            alert(
                  'Expense submission failed',
                  'Error connection to the server please check your details and try again',
                  [{ text: 'Okay' }]
                );
          }
        })
        .catch((error) => {
          console.log('Api call error', error.message);
        });
    };
    
    return (
        <div>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                    <div className='container my-2'>

                    <div className='my-1 py-1 bg-light p-2'><Link className='px-1' to ='/'>Home</Link> <i className='bi bi-chevron-right'></i> <Link className='mx-1 px-1' to ='/organisationsuser'>Organisations</Link></div>

                <h3>Expense Manage ({org})</h3>
                <div className='my-2 d-flex justify-content-between'>
                  <div className='align-items-center'>
                      <div className='p-2'>{errorMsg !== '' ? <span className='text-danger'>{errorMsg}</span> : null} {msg !== '' ? <span className='text-dark'>{msg}</span> : null}</div>
                  </div>
                </div>

                <Formik
                    initialValues={{
                      expid: expense.expid != '' ? expense.expid : '',
                      exptype: expense.exptype != '' ? expense.exptype : '',
                      expitem: expense.expitem != '' ? expense.expitem : '',
                      expamount: expense.expamount != '' ? expense.expamount : '',
                      expstatus: expense.expstatus != '' ? expense.expstatus : '',
                      expusername: expense.expusername != '' ? expense.expusername : userData.username,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                        <div className='my-2'>
                            <div className='row'>
                                <div className='col-md-6'>
                                        <div className='form-group my-1'>
                                    <div>
                                    <label htmlFor="exptype">Type</label>
                                    </div>
                                    <Field
                                        as="select"
                                        id="exptype"
                                        name="exptype"
                                        value={values.exptype}
                                        onChangeText={handleChange('exptype')}
                                        placeholder="Status"
                                        placeholderTextColor="#999"
                                        onBlur={handleBlur('exptype')}
                                        autoCapitalize="none"
                                        style={{
                                        padding: 4
                                    }}
                                    className="form-control"
                                    >
                                        <option value="">Select...</option>
                                        <option value="General">General</option>
                                        <option value="Operations">Operations</option>
                                    </Field>

                                    {touched.exptype && errors.exptype && <div className='py-1'>
                                        <span className='text-danger'>{touched.exptype && errors.exptype}</span>
                                    </div>}
                                    </div>


                                    <div className='form-group my-2'>
                                    <div>
                                        <label htmlFor="expitem">Item</label>
                                    </div>
                                    <Field
                                        as='textarea'
                                        id="expitem"
                                        name="expitem"
                                        value={values.expitem}
                                        placeholder="Item"
                                        onBlur={handleBlur('expitem')}
                                        autoCapitalize="none"
                                        style={{
                                        padding: 4,
                                    }}
                                    rows='2'
                                    className="form-control"
                                    />
                                    {touched.expitem && errors.expitem && <div className='py-1'>
                                            <span className='text-danger'>{touched.expitem && errors.expitem}</span>
                                    </div>}
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group my-1'>
                                        <div>
                                        <label htmlFor="expamount">Amount</label>
                                        </div>
                                        <Field
                                            id="expamount"
                                            name="expamount"
                                            value={values.expamount}
                                            placeholder="Amount"
                                            onBlur={handleBlur('expamount')}
                                            autoCapitalize="none"
                                            style={{
                                            padding: 4
                                        }}
                                        className="form-control"
                                        />
                                        {touched.expamount && errors.expamount && <div className='py-1'>
                                            <span className='text-danger'>{touched.expamount && errors.expamount}</span>
                                        </div>}
                                        </div>


                                        <div className='form-group my-2'>
                                        <div>
                                            <label htmlFor="expdate">Date</label>
                                        </div>
                                        
                                        <DatePicker 
                                                className='form-control' 
                                                selected={expdate} 
                                                onChange={(date) => setExpdate(date)}
                                                dateFormat="yyyy-MM-dd"  
                                            />  
                                        </div>
                                    
                                </div>
                            </div>

                            </div>
                            <button
                            type='submit'
                            buttonType="outline"
                            onClick={handleSubmit}
                            title="Submit"
                            disabled={!isValid || isSubmitting}
                            loading={isSubmitting}
                            className='btn btn-primary'
                            >
                                Submit
                            </button>


                            <button
                            onClick={() => navigate(-1)}
                            name="Cancel"
                            className='btn btn-secondary px-2 mx-2'
                            >
                                Cancel
                            </button>
                        </form>
                    )}
                  </Formik>
                              </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OrgExpenseUpdate;