import React, { useState, useEffect, useContext } from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import { UpdateProfilePictureAdmin, UpdateProfileIdentificationAdmin, DashboardMenu, NavigationBar } from '../components';
import { Header } from '.';
import { SiteContext } from '../context';

const UserProfileDisplay = () => {
  const {apiUrl} = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem('user'));

  const [user, setUser] = useState({});
  const [msg, setMsg] = useState('');
  const [updateItem, setUpdateItem] = useState({});
  const [showUpdatePictureModal, setShowUpdatePictureModal] = useState(false);

  let location = useLocation();
  
  let id = location.state;

  useEffect(() => {
    getUser();
  },[id])

  const getUser = () => {
    fetch(`${apiUrl}/api/users.php?user=${id}&action=fetchsingle&role=${userData.role}`, {
        method:'get'
      })
      .then((response) => response.json())
      .then(async(res) => {
        if(res.status === 200) {
          await setUser(res.user);
        } else if(res.status === 400) {
          setMsg(res.msg);
        }
      })
      .catch((err) => {
          console.log(err)
      })

  }

  const handlePictureUpdate = (user) => {
    setUpdateItem(user);
    setShowUpdatePictureModal(true);
  }

    return (
      <div>
          <Header />
          <div>
            <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                        <div className='container my-2'>
                        <NavigationBar section={'Users'} goback={true} />


                    <h3 className='my-2'>{user.name}'s Profile</h3>

                    <div className='row'>
                      <div className='col-md-6'>
                      <UpdateProfilePictureAdmin user={user} setUser={setUser} handleUpdate={handlePictureUpdate} />
                      </div>
                      {user.role !== 'customer' && 
                      <div className='col-md-6'>
                      <UpdateProfileIdentificationAdmin user={user} setUser={setUser} handleUpdate={handlePictureUpdate} />                        
                      </div>}
                    </div>

              <table className='container p-3'>
                <tbody>
                    <tr className='my-1'>
                      <td className='p-2'>Name</td>
                      <td className='p-2'>{user.name}</td>
                    </tr>  
                    <tr className='my-1'>
                      <td className='p-2'>Username</td>
                      <td className='p-2'>{user.username}</td>
                    </tr>  
                    <tr className='my-1'>
                      <td className='p-2'>Phone</td>
                      <td className='p-2'>{user.phone}</td>
                    </tr>  
                    <tr className='my-1'>
                      <td className='p-2'>Email</td>
                      <td className='p-2'>{user.email}</td>
                    </tr>  
                    <tr className='my-1'>
                      <td className='p-2'>Address</td>
                      <td className='p-2'>{user.address}</td>
                    </tr>  
                    <tr className='my-1'>
                      <td className='p-2'>State</td>
                      <td className='p-2'>{user.residencestate}</td>
                    </tr>  
                </tbody>
              </table> 
              </div>
              </div>
              </div>
       </div>
    </div>
    )
}

export default UserProfileDisplay;