import React, {useContext} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { 
    Home,
    Login, 
    Signup, 
    SignupDriver, 
    Profile, 
    AdminDashboard, 
    DriverDashboard, 
    Footer,
    Register,
    Account,
    Users,
    Drivers,
    AdminProducts,
    UserDashboard,
    UserProfileDisplay,
    FleetOwners,
    UpdatePassword,
    Products,
    Transactions,
    TransactionsUser,
    DriveRequests,
    DriveRequestsDrivers,
    TransactionDetail,
    Contact,
    AboutUs,
    Articles,
    ArticleAdd,
    ArticleUpdate,
    Blogs,
    Blog,
    BlogLists,
    AdminProductsCat,
    ProductsCategory,
    ProductsList,
    SitePages,
    SitePageAdd,
    SitePageUpdate,
    Faqs,
    FaqAdd,
    FaqUpdate,
    SiteConfigUpdate,
    SiteConfig,
    AdminFaqs,
    FaqsCategory,
    FaqsList,
    PageDetail,
    AdminNewsletters,
    NewsletterAdd,
    NewsletterUpdate,
    NothingMatch,
    PhoneSignup,
    OrgAdd,
    OrgMemberAdd,
    OrgMemberUpdate,
    OrgMembers,
    OrgUpdate,
    Orgs,
    ContributionAdd,
    ContributionPaymentUpdate,
    ContributionUpdate,
    Contributions,
    OrgsUser,
    OrgUpdateUser,
    ContributionAddUser,
    ContributionUpdateUser,
    ContributionsUser,
    ContributionsDashboard,
    ContributionsDashboardSingle,
    OrgExpenseAdd,
    OrgExpenseUpdate,
    OrgExpenses,
    ContributionsDashboardExpenses,
    ContributionsDashboardAll
 } from '../screens';
 import { ProductDetail, OrderComplete, Checkout, OrderForm } from '../components';
import { SiteContext } from '../context';

const SiteRoutes = () => {

    const { isLoggedOn  } = useContext(SiteContext);

    const loggedin = localStorage.getItem('loggedin');

    const userloggedOn = loggedin || isLoggedOn;
    
    const RequireAuth = ({children}) => {
        
        if(!userloggedOn) {
            return <Navigate to='/login' />
        }

        return children
    }

    const MainNav = () => {
        return (
            <Routes>
                <Route path='/' element={<Home/>} />
                <Route path='/login' element={<Login/>} />
                <Route path='/signup' element={<Signup/>} /> 
                <Route path='/signup/driver' element={<SignupDriver/>} /> 
                <Route path='/register' element={<Register />} />
                <Route path='/products' element={<Products/>} />
            </Routes>
        )
    }


    const AuthNav = () => {
        return (
            <Routes> 
                <Route path='/' element={<Home/>} />
                <Route path='/login' element={<Login/>} />
                <Route path='/signup' element={<Signup/>} /> 
                <Route path='/signup/driver' element={<SignupDriver/>} /> 
                <Route path='/register' element={<Register />} />
                <Route path='/phonesignup' element={<PhoneSignup/>} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/about' element={<AboutUs />} />
                <Route path='/products' element={<Products />}>
                    <Route path='' element={<ProductsList />} />
                    <Route path='category/:slug' element={<ProductsCategory />} /> 
                    <Route path='detail/:slug' element={<ProductDetail/>} />                    
                </Route> 
                <Route path='/addorder' element={<RequireAuth><OrderForm /></RequireAuth>} />
                <Route path='/transactions' element={<RequireAuth><Transactions /></RequireAuth>} />
                <Route path='/mytransactions' element={<RequireAuth><TransactionsUser /></RequireAuth>} />
                <Route path='/order/:orderref' element={<RequireAuth><TransactionDetail/></RequireAuth>} />
                <Route path='/ordercomplete/:orderref' element={<RequireAuth><OrderComplete /></RequireAuth>} />
                <Route path='/checkout' element={<RequireAuth><Checkout /></RequireAuth>} />
                <Route path='/users' element={<RequireAuth><Users /></RequireAuth>} />
                <Route path='/addarticle' element={<RequireAuth><ArticleAdd /></RequireAuth>} />
                <Route path='/updatearticle' element={<RequireAuth><ArticleUpdate /></RequireAuth>} />
                <Route path='/articles' element={<RequireAuth><Articles /></RequireAuth>} />
                <Route path='/updateorganisationuser' element={<RequireAuth><OrgUpdateUser /></RequireAuth>} />
                <Route path='/organisationsuser' element={<RequireAuth><OrgsUser /></RequireAuth>} />
                <Route path='/addorganisation' element={<RequireAuth><OrgAdd /></RequireAuth>} />
                <Route path='/updateorganisation' element={<RequireAuth><OrgUpdate /></RequireAuth>} />
                <Route path='/organisations' element={<RequireAuth><Orgs /></RequireAuth>} />
                <Route path='/addorgmemberuser' element={<RequireAuth><OrgMemberAdd /></RequireAuth>} />
                <Route path='/updateorgmemberuser' element={<RequireAuth><OrgMemberUpdate /></RequireAuth>} />
                <Route path='/orgmembersuser' element={<RequireAuth><OrgMembers /></RequireAuth>} />
                <Route path='/addorgexpenseuser' element={<RequireAuth><OrgExpenseAdd /></RequireAuth>} />
                <Route path='/updateorgexpenseuser' element={<RequireAuth><OrgExpenseUpdate /></RequireAuth>} />
                <Route path='/orgexpensesuser' element={<RequireAuth><OrgExpenses /></RequireAuth>} />
                <Route path='/adminaddcontribution' element={<RequireAuth><ContributionAdd /></RequireAuth>} />
                <Route path='/adminupdatecontribution' element={<RequireAuth><ContributionUpdate /></RequireAuth>} />
                <Route path='/admincontributions' element={<RequireAuth><Contributions /></RequireAuth>} />
                <Route path='/addcontributionuser' element={<RequireAuth><ContributionAddUser /></RequireAuth>} />
                <Route path='/updatecontributionuser' element={<RequireAuth><ContributionUpdateUser /></RequireAuth>} />
                <Route path='/contributionsuser' element={<RequireAuth><ContributionsUser /></RequireAuth>} />
                <Route path='/contributiondashboard' element={<ContributionsDashboard />} >
                    <Route path='' element={<ContributionsDashboardAll />} />
                    <Route path=':slug' element={<ContributionsDashboardAll />} />
                    <Route path='single/:slug' element={<ContributionsDashboardSingle />} />
                    <Route path='expenses/:slug' element={<ContributionsDashboardExpenses />} />
                </Route>
                <Route path='/paymentlist' element={<RequireAuth><ContributionPaymentUpdate /></RequireAuth>} />
                <Route path='/addpage' element={<RequireAuth><SitePageAdd /></RequireAuth>} />
                <Route path='/updatepage' element={<RequireAuth><SitePageUpdate /></RequireAuth>} />
                <Route path='/adminpages' element={<RequireAuth><SitePages /></RequireAuth>} />
                <Route path='/addfaq' element={<RequireAuth><FaqAdd /></RequireAuth>} />
                <Route path='/updatefaq' element={<RequireAuth><FaqUpdate /></RequireAuth>} />
                <Route path='/adminfaqs' element={<RequireAuth><AdminFaqs /></RequireAuth>} />
                <Route path='/addnewsletter' element={<RequireAuth><NewsletterAdd /></RequireAuth>} />
                <Route path='/updatenewsletter' element={<RequireAuth><NewsletterUpdate /></RequireAuth>} />
                <Route path='/adminnewsletters' element={<RequireAuth><AdminNewsletters /></RequireAuth>} />
                <Route path='/adminsiteupdate' element={<RequireAuth><SiteConfigUpdate /></RequireAuth>} />
                <Route path='/adminsiteconfig' element={<RequireAuth><SiteConfig /></RequireAuth>} />
                <Route path='/drivers' element={<RequireAuth><Drivers /></RequireAuth>} />
                <Route path='/driverrequests' element={<RequireAuth><DriveRequests/></RequireAuth>} />
                <Route path='/driverrequests/pending' element={<RequireAuth><DriveRequestsDrivers/></RequireAuth>} />
                <Route path='/fleetowners' element={<RequireAuth><FleetOwners/></RequireAuth>} />
                <Route path='/adminproducts' element={<RequireAuth><AdminProducts/></RequireAuth>} />
                <Route path='/adminproductscat' element={<RequireAuth><AdminProductsCat/></RequireAuth>} />
                <Route path='/page/:id' element={<PageDetail/>} />
                <Route path='/posts' element={<BlogLists/>}>
                    <Route path='' element={<Blogs/>} />
                    <Route path='post/:id' element={<Blog />} />
                </Route>
                <Route path='/faqs' element={<FaqsList/>}>
                    <Route path='' element={<Faqs/>} />
                    <Route path='category/:slug' element={<FaqsCategory/>} />
                </Route>
                <Route path='/profile' element={<RequireAuth><Profile /></RequireAuth>} />
                <Route path='/changepassword' element={<RequireAuth><UpdatePassword /></RequireAuth>} />
                <Route path='/userprofile' element={<RequireAuth><UserProfileDisplay /></RequireAuth>} />
                <Route path='/account' element={<RequireAuth><Account /></RequireAuth>} />
                <Route path='/admin' element={<RequireAuth><AdminDashboard /></RequireAuth>} />
                <Route path='/user' element={<RequireAuth><UserDashboard /></RequireAuth>} />
                <Route path='/driver' element={<RequireAuth><DriverDashboard /></RequireAuth>} />
                <Route path='*' element={<NothingMatch />} />
            </Routes>
        )
    }

    return (
        <Router>
            <AuthNav />
            <Footer />
        </Router>
    )
}

export default SiteRoutes;