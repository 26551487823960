import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SiteContext } from '../context';

const validationSchema = Yup.object().shape({
    passcode: Yup.string()
        .label('Passcode')
        .required()
})

const SearchOrgBox = ({setShowModalSearch}) => {

    let navigate = useNavigate();

    const { apiUrl, formatAmount } = useContext(SiteContext);

    const [item, setItem] = useState({});
    const [phone, setPhone] = useState('');
    const [msg, setMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const handleSubmit = (values, { setSubmitting, resetForm }) => {

        setPhone(values.phone);

        let formData = new FormData();
    
        formData.append('passcode', values.passcode);
        formData.append('action', 'search');
      
          return fetch(`${apiUrl}/api/organisations.php`, {
            method: 'post',
            body: formData
          })
              .then((res) => res.json())
            .then( (res) => {
              console.log(res);
      
              if(res.status === 200) {
                  resetForm({});
                  setSubmitting(false);
                  setItem(res.org);
              } else if(res.status === 400) {
                setErrorMsg(res.msg);
                setTimeout(() => {
                  setErrorMsg('');
                }, 5000);
                setSubmitting(false);
              } else {
                alert(
                      'Problem connecting to the server',
                      'Error connection to the server please check your details and try again',
                      [{ text: 'Okay' }]
                    );
              }
            })
            .catch((error) => {
              console.log('Api call error', error.message);
            });
        };
        
        
    
  
    return (
        <div className='container'>
        <div className='my-1 py-2'>
            <h4>Login with access code</h4>

              <div>
              <Formik
                    initialValues={{
                      passcode: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit}>
                          <div className='bg-dark rounded p-3'>
                          {errorMsg !== '' ? <span className='py-2 my-2 text-white'>{errorMsg}</span> : null}                  

                          <div className='form-group py-3'>
                                <Field
                                id="passcode"
                                name="passcode"
                                value={values.passcode}
                                placeholder="Passcode"
                                onBlur={handleBlur('passcode')}
                                autoCapitalize="none"
                                style={{
                                    padding: 4
                                }}
                                className="form-control"
                                />
                                {touched.passcode && errors.passcode && <div className='py-1'>
                                    <span className='text-danger'>{touched.passcode && errors.passcode}</span>
                                </div>}

                          </div>

                             <button 
                             type='submit' 
                              title="Search"
                              disabled={!isValid || isSubmitting}
                              onClick={handleSubmit} 
                              className="btn btn-primary">Search 
                              </button>
                            
                            </div>

                          </form>
                    )}
                  </Formik>
        </div>
        {item.hasOwnProperty('orgname') &&
                <div className='w-100 my-1 py-1 border'>

                <Link className='text-decoration-none text-dark text-left border border-light bg-light rounded py-2 m-2' to='/contributiondashboard' state={{org: item }}>
                    <div className='justify-content-start align-content-center p-1'>
                        <div className='float-start'>
                              <img className='img-fluid rounded px-2' src={`${apiUrl}/${item.orglogo}`} height={70} width={70} alt={item.orgname} />
                        </div> 
                        <div className='px-2 mx-2'>
                          <span className='h5'>{item.orgname}</span><br />
                          <span className='text-muted sm'>{item.orgcat}</span><br />
                          <span className='float-end'>Enter</span>

                        </div>
                    </div>
                </Link>    

                </div>}


        </div>
        </div>
    )
}

export default SearchOrgBox;