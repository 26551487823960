import React, {useEffect} from 'react';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import { CategoriesMenu } from '../components';


const Products = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])


    return (
        <div>
            <Header />

            <div className='container-fluid'>
            <div className='row'>
                <div className='order-2 order-md-1 col-md-3'>
                    <CategoriesMenu />
                </div>
                <div className='order-1 order-md-2 col-md-9'>

                    <Outlet />     
                </div>
            </div>
            </div>
        </div>
    )
}

export default Products;