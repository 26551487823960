import { initializeApp } from "firebase/app";

  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyCSNCyB-3cXAWIFiVDPz0yWB5Jg2D-AkCk",
    authDomain: "orban-springs.firebaseapp.com",
    projectId: "orban-springs",
    storageBucket: "orban-springs.appspot.com",
    messagingSenderId: "86148380267",
    appId: "1:86148380267:web:2306ff4d6746bf2a1e232e"
  };

  // Initialize Firebase
  const firebase = initializeApp(firebaseConfig);

  export default firebase;